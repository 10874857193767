<template>
    <div ref="menuLateral" class="sidenav">
      <a href="javascript:;" class="close-btn" @click="this.closeSideNavButton()">&times;</a>

      <aside>
        <nav>
          <!-- génération des onglets -->
          <div v-for="item in menuComputed" :key="item" class="conteneur-onglets-sous-onglets" :ref='addOnglets'>

            <div class="onglet" v-if="item.externalUrl">

              <a :href=" item.externalUrl" target="_blank" rel="noopener noreferrer" :title="item.label">
                <div class="conteneur-icone-texte">
                  <!-- génération conditionnelle icône chevron -->
                  <span v-if="item.items" class="p-panelmenu-icon pi pi-chevron-right mon-chevron"></span>

                  <!-- icône -->
                  <span :class="`barre-laterale-icone ${item.icon}`"></span>

                  <!-- texte -->
                  <span>
                    <div class="barre-laterale-texte">
                      {{ item.label }}
                    </div>
                  </span>

                </div>
              </a>
            </div>
            <!-- onglet avec lien Rechercher -->
            <div class="onglet" v-else-if="item.url">

              <router-link
                :to="{ name:'Rechercher-Endroit', query: { keepEndroits: true }}"
                :title="item.label"
              >
                <div class="conteneur-icone-texte">
                  <!-- génération conditionnelle icône chevron -->
                  <span v-if="item.items" class="p-panelmenu-icon pi pi-chevron-right mon-chevron"></span>

                  <!-- icône -->
                  <span :class="`barre-laterale-icone ${item.icon}`"></span>

                  <!-- texte -->
                  <span>
                    <div class="barre-laterale-texte">
                      {{ item.label }}
                    </div>
                  </span>

                </div>
              </router-link>

            </div>

            <!-- onglet avec lien Voir tout -->
            <div class="onglet" v-else-if="item.name === 'tout'">

              <router-link
                  :to="{ name: 'Home', params: { section: item.name }, query: { keepEndroits: true }}"
                  :title="item.label"
              >
                <div class="conteneur-icone-texte">
                  <!-- génération conditionnelle icône chevron -->
                  <span v-if="item.items" class="p-panelmenu-icon pi pi-chevron-right mon-chevron"></span>

                  <!-- icône -->
                  <span :class="`barre-laterale-icone ${item.icon}`"></span>

                  <!-- texte -->
                  <span>
                    <div class="barre-laterale-texte">
                      {{ item.label }}
                    </div>
                  </span>

                </div>
              </router-link>

            </div>

            <!-- onglet sans lien -->
            <div class="onglet" v-else @click="gereClicOnglet">
              <div class="conteneur-icone-texte">
                <!-- icône -->
                <span :class="`barre-laterale-icone ${item.icon}`"></span>

                <!-- texte -->
                <span>
                  <div class="barre-laterale-texte">
                    {{ item.label }}
                  </div>
                </span>

                <!-- génération conditionnelle icône chevron -->
                <span v-if="item.items" class="p-panelmenu-icon pi pi-chevron-right mon-chevron"></span>

              </div>
            </div>

            <!-- génération des sous-éléments de l'onglet s'il y a lieu -->
            <template v-if="item.items !== undefined" >
              <div v-for="subItem in item.items" :key="subItem" :ref="addSousOnglets">
                <router-link
                  :to="{ name: 'Home', params: { section: item.name }, query: { keepEndroits: true }, hash: '#' + subItem.to.params.idListData }"
                  :title="subItem.label"
                  :class="'lien-sous-onglet' + (subItem.isActive ? ' link-active' : '')"
                >
                  <div class="sous-onglet">
                      {{ subItem.label }}
                  </div>
                </router-link>
              </div>
            </template>
            <!-- fin de la boucle secondaire -->

          </div> <!-- fin de la boucle principale -->
        </nav>
      </aside>
    </div>

    <span class="hamburger" @click="this.openSideNavButton()">&#9776;</span>

</template>

<script lang="ts">
import menu from '@/configs/Menu.json'
import GlobalParamsHandlers from '../globalParams/GlobalParamsHandlers'
import { PropType, defineComponent } from 'vue'
import { MenuSectionItem } from '@/DTO/MenuSectionItem'

export default defineComponent({
  name: 'Sidebar',
  data () {
    return {
      ongletsTableau: new Array<HTMLElement>(),
      ongletsSousTableau: new Array<HTMLElement>()
    }
  },
  computed: {
    menuComputed () {
      const routeHash = this.$route.hash
      return menu.map((item) => {
        if (item.items !== undefined) {
          item.items = item.items.map((subItem: any) => {
            subItem.isActive = ('#' + subItem.to.params.idListData.toString()) === routeHash
            return subItem
          })
        }
        return item
      })
    }
  },
  methods: {
    addOnglets (node: HTMLElement) {
      if (node) {
        this.ongletsTableau.push(node);
      }
    },
    addSousOnglets (node: HTMLElement) {
      if (node) {
        this.ongletsSousTableau.push(node);
      }
    },
    gereClicOnglet (event: MouseEvent) {
      // variables
      const conteneurOngletsSousOngletsActif = (event.target as HTMLElement).closest('.conteneur-onglets-sous-onglets') as HTMLElement;

      // boucle sur chacun des enfants du conteneur d'onglets et de sous-onglets
      for (const onglet of conteneurOngletsSousOngletsActif.children) {
        const chevron = conteneurOngletsSousOngletsActif.firstElementChild!.firstElementChild!.lastElementChild;

        // affichage/masquage seulement avec sous-onglets, pas l'onglet principal
        if (onglet.classList.contains('onglet') === false) {
          // affichage des sous-onglets
          if (onglet.classList.contains('plie')) {
            chevron!.classList.remove('pi-chevron-right');
            chevron!.classList.add('pi-chevron-down');
            onglet.classList.remove('plie');
            /**
             * ajout/retrait d'une classe quand on plie/déplie les sous-onglets
             * l'onglet n'est pas vraiment actif, mais il en a le  style
             */
            onglet.parentElement!.classList.add('onglet-pseudo-actif');
          // masquage des sous-onglets
          } else {
            chevron!.classList.add('pi-chevron-right');
            chevron!.classList.remove('pi-chevron-down');
            onglet.classList.add('plie')
            /**
             * ajout/retrait d'une classe quand on plie/déplie les sous-onglets
             * l'onglet n'est pas vraiment actif, mais il en a le  style
             */
            onglet.parentElement!.classList.remove('onglet-pseudo-actif');
          }
        }
      }
      // fin du bloc
    },
    afficheCacheOnglets () {
      // on commence par cacher tous les sous-onglets
      for (const item of this.ongletsSousTableau) {
        item.classList.add('plie');
      }
      // fin de la boucle

      // on affiche ensuite seulement ce qui doit l'être (actif)
      for (const sousOnglet of this.ongletsSousTableau) {
        // variables temporaires
        const parent = sousOnglet.parentElement;
        const chevron = sousOnglet.parentElement!.firstElementChild!.firstElementChild!.lastElementChild;

        // pas question de faire break ici, quand chaque sous-onglet est à déplier un à un
        if (parent!.classList.contains('onglet-actif')) {
          sousOnglet.classList.remove('plie');
          chevron!.classList.remove('pi-chevron-right');
          chevron!.classList.add('pi-chevron-down');
        }
      }
      // fin de la boucle
    },
    changeCouleursOnglets () {
      // vérifications sur les onglets
      for (const item of this.ongletsTableau) {
        // vérifie présence class active sur onglets principaux
        const estActifOngletSeul = item.firstElementChild!.firstElementChild!.classList[0] === 'router-link-active';

        // style de l'onglet quand il est actif
        if (estActifOngletSeul) {
          item.classList.add('onglet-actif');

          // On Profite de l'occasion pour récupérer le titre des onglets principaux quand il n'y a pas de sous-onglets. Cas avec sous-onglets ci-dessous.
          GlobalParamsHandlers.pageTitle = item.innerText;

          // il ne peut y avoir qu'un seul lien actif, donc fin de la boucle
          break;
        }
      }

      // vérifications sur les sous-onglets
      for (const item in this.ongletsSousTableau) {
        // vérifie présence de la classe active sur le lien autour du sous-onglet
        const sontActifsSousOnglets = this.ongletsSousTableau[item]!.closest('div')!.firstElementChild!.classList[0] === 'router-link-active';

        // style de l'onglet quand sous-onglet actif; le style du sous-onglet se fait par le CSS
        if (sontActifsSousOnglets) {
          this.ongletsSousTableau[item].parentElement!.classList.add('onglet-actif');
          // il ne peut y avoir qu'un seul lien actif

          GlobalParamsHandlers.pageTitle = (this.ongletsSousTableau[item].parentElement!.children[0] as HTMLElement).innerText;
          break;
        }
      }
    },
    // pour ouvrir menu latéral
    openSideNavButton () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;
      menuLateral.style.width = '278px';
    },
    // pour fermer menu latéral
    closeSideNavButton () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;
      menuLateral.style.width = '0';
    },
    /**
     * Si l'utilisateur s'amuse à redimensionner manuellement la taille de son navigateur, le menu de navigation latérale détermine lui-même s'il doit s'afficher ou non.
     *
     * Sert à éviter un bogue où le menu latéral se retrouvait avec une largeur de 0 en mode desktop si l'utilisateur avait décidé de le cacher en mode mobile avant de repassser en mode desktop.
     *
     * Important! Si on change la largeur de 760 ci-dessous, il faut aussi changer ce nombre dans le CSS (media queries), et surtout faire certains tests pour s'assurer qu'il n'y a pas un fossé/intervalle où le JS et le CSS ne sont pas pris en compte.
     *
     * Pour déboguer cette fonction, il peut être utile d'utiliser ces lignes de code.
     *
     * console.log(`clientWidth: ${document.documentElement.clientWidth}`);
     * console.log(`innerWidth: ${window.innerWidth}`);
     * console.log('===');
     *
     * clientWidth = sans la largeur de la barre de défilement
     * innerWidth = avec la largeur de la barre de défilement
     *
     */
    getDimensions () {
      const menuLateral = this.$refs.menuLateral as HTMLElement;

      // en mode desktop, on force une largeur de 278
      if (window.innerWidth >= 760) {
        menuLateral.style.width = '278px';
      // autrement, il faut cliquer sur le bouton du menu latéral pour l'afficher
      } else {
        menuLateral.style.width = '0';
      }
    }
  },
  props: {
    sectionsAffiche: {
      type: Array as PropType<Array<MenuSectionItem>>,
      required: true
    }
  },
  mounted () {
    // Fix pour le load du menu
    this.changeCouleursOnglets();
    this.afficheCacheOnglets();
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted () {
    // ce n'est pas strictement nécessaire de retirer le gestionnaire, mais ce serait une bonne pratique
    // https://masteringjs.io/tutorials/vue/resize-event
    window.removeEventListener('resize', this.getDimensions);
  }
});
</script>

<style lang="scss" scoped>

// couleurs utilisées
$colorAside: #001B33;
$colorFont: #DCF5F2;
$colorIcons: #38A295;
$colorHover: #3A5777;
$colorHoverSousOnglet: #3A5777;

aside {
  user-select: none;

  // règle pour la barre de défilement latérale
  // il est possible de cacher la barre de défilement sans désactiver sa fonctionnalité si la cliente en formule le souhait, mais les règles à employer seraient différentes selon le navigateur
  // https://blog.hubspot.com/website/hide-scrollbar-css
  height: 100vh;
  overflow-y: scroll;

  // Règle pour le menu sticky.
  // La demande a été formulée de créer un menu en sticky, mais cela ne sert à rien comme la présence de la barre de défilement fait déjà le travail. Code ci-dessous conservé au cas où la cliente change d'idée. 2022.-05-03.
  position: sticky;
  top: 0;

  nav {
    text-align-last: left;
    width: 260px;

    a {
      color: $colorFont;
      text-decoration: none;
    }

    .onglet {
      text-align: left;

      &:hover {
        cursor: pointer;
        background: $colorHover;
      }

      // conteneur avec icônes(s) et titre onglet principal
      .conteneur-icone-texte {
        padding: 1rem;

        // pour aligner les chevrons une même colonne; ceci fonctionne aussi avec .mon-chevron pour parfaire l'alignement
        display: grid;
        grid-template-columns: auto auto 1fr;

        .barre-laterale-icone {
          height: 25px;
          margin-right: 0.5rem;
          text-align: center;
          width: 25px;
        }

        .barre-laterale-texte {
          color: $colorFont;
          text-align: left;
        }

        // liste d'icônes de la barre latérale de navigation/menu

        .icon-hearth {
          background-image: url('../assets/icons/icon-hearth.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
        .icon-book {
          background-image: url('../assets/icons/icon-book.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
        .icon-eye {
          background-image: url('../assets/icons/icon-eye.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
        .icon-group {
          background-image: url('../assets/icons/icon-group.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-currency-dollar {
          background-image: url('../assets/icons/icon-currency-dollar.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-map {
          background-image: url('../assets/icons/icon-map.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-building {
          background-image: url('../assets/icons/icon-building.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-flag {
          background-image: url('../assets/icons/icon-flag.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-search {
          background-image: url('../assets/icons/icon-search.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-chart-line {
          background-image: url('../assets/icons/icon-chart-line.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-car {
          background-image: url('../assets/icons/icon-car.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .icon-home {
          background-image: url('../assets/icons/icon-home.svg');
          background-repeat: no-repeat;
          background-position: center;
        }

        .mon-chevron {
          color: $colorIcons;

          // pour centrer chevron (flèche/triangle) en hauteur et aligner à droite en largeur
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
    // fin .onglet

    // sous-onglets; sont frères de la classe .onglet
    .lien-sous-onglet {
      color: $colorFont;
      text-decoration: none;

      .sous-onglet {
        padding: 0.75rem 1rem;
        text-align: left;

        &:hover {
          background: $colorHover;
        }
      }
    }

  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// cas spéciaux
.plie {
  display: none;
  transition: all 2s;
}

.link-active{
  // la couleur ne s'applique pas sans la mention "important"
  color: white !important;
}

// La classe .onglet-actif sert aussi pour la logique (JS).
// De là vient le besoin de créer une autre classe, .onglet-pseudo-actif.
// Cela sert pour les clics sur les onglets qui sont simplement dépliés.
// Ils ne sont pas forcément parents de .router-active-link.
.onglet-actif, .onglet-pseudo-actif {
  background: rgba(58, 87, 119, 0.5);

  .conteneur-icone-texte {
    border-bottom: 2px solid #38A295;

    // création d'une grille de trois éléments; pour afficher le chevron (flèche/triangle) à la fin complètement de ces trois colonnes
    display: grid;
    grid-template-columns: auto auto 1fr;
  }

  .sous-onglet {
    background-color:rgba(24, 48, 73, 0.829);
    color:#91A0C8;
    &:hover {
      background: rgba(58, 87, 119, 0.5);
    }
  }
}

// règles de style pour le panneau coulissant
// Important! Si on change les deux largeurs (759 et 760) ci-dessous, il faut aussi changer ce nombre dans getDimensions(), ci-dessus!
@media screen and (max-width: 759px) {
  .close-btn {
    margin: 10px;
  }

  .hamburger {
    cursor: pointer;
    font-size: 30px;
    margin: 15px 0 0 15px;
  }

  .sidenav {
    color: white;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav .close-btn {
    color: white;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    text-decoration: none;
  }
}
@media screen and (min-width: 760px) {
  /* pour cacher bouton hamburger et bouton de fermeture panneau coulissant en mode desktop */
  a.close-btn, .hamburger {
    display: none;
  }

  // pour que tout le bas de la zone latérale ait la même couleur que la barre latérale de menu
  .sidenav {
    background: $colorAside;
  }
}
</style>
