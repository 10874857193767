<template>
    <div class="error-box" v-if="errorMessage.length > 0">
      {{ errorMessage }}
    </div>

    <div
      class="margin-entre-reponses-questionnaire"
      :key="reponse"
      v-for="reponse of questionProp.reponsesPossibles"
    >

    <label :for=" reponse.texteReponse">{{ reponse.texteReponse }}</label>
      <InputNumber
        @input="onValueChange"
        v-model="value[reponse.texteReponse]"
        mode="decimal"
        :min="0"
        showButtons
        :step="0.5"
        :minFractionDigits="1"
        buttonLayout="horizontal"
        decrementButtonclass="p-button-danger" incrementButtonclass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
        />

    </div>

  </template>

<script lang="ts">
import { defineComponent } from 'vue'
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
  name: 'InputMultiTextMaison',
  components: {
    InputNumber
  },
  data () {
    return {
      errorMessage: '',
      value: {} as any
    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange () {
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
</script>

  <style lang="scss" scoped>
    // pour que le libellé ne soit pas directement collé sur le bouton radio ou la case à cocher
    label {
      margin-left: 5px;
      min-width: 250px;
    }

    .margin-entre-reponses-questionnaire {
      // pour que le texte de la question, ne se retrouve pas sous le bouton radio/la case à cocher
      display: flex;
      align-items: center;
      justify-content: flex-start;

      // pour que les questions soit légèrement décollées entre elles
      margin: 10px;
    }
  </style>
  <style>
    .margin-entre-reponses-questionnaire .p-button {
      background: #324e9c !important;
      border-color: #324e9c !important;
    }
  </style>
