<template>
  <div class="error-box" v-if="errorMessage.length > 0">
    {{ errorMessage }}
  </div>

  <div
    class="margin-entre-reponses-questionnaire"
    :key="reponse.texteReponse"
    v-for="reponse of questionProp.reponsesPossibles"
  >
    <Checkbox
      @change="onValueChange"
      :name="reponse.texteReponse"
      :value="reponse.texteReponse"
      v-model="value"
    />
    <label :for=" reponse.texteReponse">{{ reponse.texteReponse }}</label>
  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Checkbox from 'primevue/checkbox';

export default defineComponent({
  name: 'InputCheckboxesMaison',
  components: {
    Checkbox
  },
  data () {
    return {
      errorMessage: '',
      value: []
    }
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange () {
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
</script>

<style lang="scss" scoped>
  // pour que le libellé ne soit pas directement collé sur le bouton radio ou la case à cocher
  label {
    margin-left: 5px;
  }

  .margin-entre-reponses-questionnaire {
    // pour que le texte de la question, ne se retrouve pas sous le bouton radio/la case à cocher
    display: grid;
    grid-template-columns: auto 1fr;

    // pour que les questions soient légèrement décollées entre elles
    margin: 10px;
  }
</style>
