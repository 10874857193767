<template>
    <MenubarMaison :model="items">
        <template #start>
          <div class="flex containerLogo">
            <h2 class="p-ml-10" >SCOLOR</h2>
          </div>
        </template>
    </MenubarMaison>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MenubarMaison from '../plugins/MenubarMaison.vue'

export default defineComponent({
  name: 'Header',
  components: {
    MenubarMaison
  },
  data () {
    return {
      items: [
        {
          label: localStorage.getItem('email'),
          icon: '',
          items: [
            {
              label: 'Déconnexion',
              icon: 'pi pi-power-off',
              command: (event: any) => {
                this.$store.dispatch('auth/logout').then(() => {
                  this.$router.push('/')
                })
              }
            }
          ]
        }
      ]
    }
  }
})
</script>

<style scoped lang="scss">

.containerLogo {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
  color:#001B33;
}
    .navbar {
        height: 7vh;
        background: white;
        box-shadow: 0px 1px 4px #15223214;
        width:100%;
        display:flex;
        flex-direction:row;
    }
    #conteneurnav2
    {
        display:flex;
        flex-direction:row;
        background-color:white;
        align-items: center;
        width:15%;
    }
    #conteneurnav1
    {
        display:flex;
        flex-direction:row;
        background-color:white;
        width:85%;
    }
    ::v-deep(.boutonInitial) {
        color:white;
        background-color: #F0753B;
        width:30px;
        padding:20px;
        border-radius:100%;
        height:30px;
        letter-spacing: 0px;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:16px;
    }
    .nometprenom
    {
        color:#001B33;
        font-weight: 500;
        letter-spacing: 0px;
        font-size:14px;
        text-align:left;
        margin-left:10px;
        width:85%;
    }
    #logo{
        padding:5px;
        float:left;
        margin-left:10px;
    }

    ::v-deep(.p-submenu-list){
      right:0;
      left:initial !important;
      padding:0 !important;
    }
    .p-menubar {
      background: white;
      justify-content: space-between;
      padding-right:2vw;
      text-align:right;
    }
</style>
