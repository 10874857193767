<template>
  <div class="errors" v-if="erreursList.length > 0">
    <template v-for="error in erreursList" :key="error">
      <div class="errors-item" v-if="selectedEndroit.includes(error.endroitData._id)">
        <div class="errors-item-message">
          <span>{{ error.endroitData.NOM_GEO }}: {{ error.errorType }}</span>
          <div class="errors-item-message-details" @click="toggleDetails(error)">
              {{ error.showDetails.value ? 'Cacher' : 'Voir' }} les détails
          </div>
        </div>
        <div class="errors-item-dev" v-if="error.showDetails.value">
          <span v-if="error.path">path: {{ error.path }}</span>
          <span v-if="error.theme">theme: {{ error.theme }}</span>
          <span v-if="error.annee">annee: {{ error.annee }}</span>
          <span v-if="error.value">value: {{ error.value }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { defineComponent, inject, PropType, Ref, ref } from 'vue'
import { GraphErrorDTO } from '../DTO/GraphErrorDTO';

export default defineComponent({
  name: 'EndroitError',
  props: {
    erreurs: {
      type: Map as PropType<Map<string, GraphErrorDTO>>,
      required: true
    }
  },
  computed: {
    erreursList () {
      return Array.of(...this.erreurs.values()).map(error => {
        return { showDetails: ref(false), ...error }
      });
    }
  },
  methods: {
    toggleDetails (error: { showDetails: Ref<boolean>, [key: string]: any }) {
      error.showDetails.value = !error.showDetails.value
    }
  },
  setup () {
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    const selectedEndroit = endroitSelectionHelper.value.getAllSelectedEndroits();
    return {
      selectedEndroit
    }
  }
})
</script>

<style scoped lang="scss">
.errors {
    display: flex;
    flex-direction: column;

    &-item {
        background-color: #FFEDED;
        color: red;
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 7px;

        &-message {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            &-details {
                user-select: none;
                cursor: pointer;
            }
        }

        &-dev {
            display: flex;
            flex-direction: column;
            background-color: #ff7373;
            color: white;
            margin: 10px 0;
            padding: 5px 10px;
            border-radius: 7px;
            width: 100%;
            align-items: flex-start;
        }
    }
}
</style>
