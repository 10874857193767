<template>
  <div :id="section.originalId">
    <!-- <h1>{{ section.nom }}</h1> -->
    <div
      v-for="graphiqueDataItem in graphiquesData"
      :key="graphiqueDataItem"
      :id="graphiqueDataItem.graphId"
      class="panelItemContainer"
    >
      <TabView :lazy="true">
        <!-- graphique -->
        <TabPanel
          header="Graphique"
          v-if="!graphiqueDataItem.graphInfo.aucunGraph"
        >
          <Exports
            :data="graphiqueDataItem.data"
            :graphInfo="graphiqueDataItem.graphInfo"
            :idCanvas="'graphique-' + graphiqueDataItem.graphInfo.id"
          >
            <GraphiqueABande
              v-if="graphiqueDataItem.graphInfo.graphType === 'bande'"
              :graphDataConverter="graphDataConverter"
              :graphInfo="graphiqueDataItem.graphInfo"
              :panelDouble="false"
              :graphAxisName="graphiqueDataItem.graphAxisName"
              :data="graphiqueDataItem.data"
              :fieldValue="graphiqueDataItem.fields"
              :translator="translator"
            />
            <GraphiqueACourbe
              v-if="graphiqueDataItem.graphInfo.graphType === 'courbe'"
              :graphInfo="graphiqueDataItem.graphInfo"
              :panelDouble="false"
              :graphAxisName="graphiqueDataItem.graphAxisName"
              :data="graphiqueDataItem.data"
              :fieldValue="graphiqueDataItem.fields"
              :translator="translator"
            />
            <GraphiqueATarte
              v-if="graphiqueDataItem.graphInfo.graphType === 'tarte'"
              :graphInfo="graphiqueDataItem.graphInfo"
              :panelDouble="false"
              :graphAxisName="graphiqueDataItem.graphAxisName"
              :data="graphiqueDataItem.data"
              :fieldValue="graphiqueDataItem.fields"
              :translator="translator"
            />
            <GraphiqueStacked
              v-if="graphiqueDataItem.graphInfo.graphType === 'stacked'"
              :graphInfo="graphiqueDataItem.graphInfo"
              :panelDouble="false"
              :graphAxisName="graphiqueDataItem.graphAxisName"
              :data="graphiqueDataItem.data"
              :fieldValue="graphiqueDataItem.fields"
              :translator="translator"
            />
            <GraphSources
              :graphInfoProp="graphiqueDataItem.graphInfo"
              :data="graphiqueDataItem.data"
            />
          </Exports>
          <EndroitError :erreurs="graphiqueDataItem.data.errors" />
        </TabPanel>
        <!-- fin onglet -->

        <!-- tableau normal -->
        <TabPanel
          header="Tableau"
          v-if="!graphiqueDataItem.graphInfo.aucunTableau"
        >
          <Exports
            :data="graphiqueDataItem.data"
            :graphInfo="graphiqueDataItem.graphInfo"
            :idCanvas="'tableau-' + graphiqueDataItem.graphInfo.id"
          >
            <Tableau
              :panelDouble="false"
              :data="graphiqueDataItem.data"
              :graphInfo="graphiqueDataItem.graphInfo"
            />
            <GraphSources
              :graphInfoProp="graphiqueDataItem.graphInfo"
              :data="graphiqueDataItem.data"
            />
          </Exports>
          <EndroitError :erreurs="graphiqueDataItem.data.errors" />
        </TabPanel>
        <!-- fin onglet -->

        <!-- tableau simplifié -->
        <!-- <TabPanel header="Tableau simplifié" v-if="!graphiqueDataItem.graphInfo.aucunTableau">
        <Exports :data="graphiqueDataItem.data" :graphInfo="graphiqueDataItem.graphInfo" :idCanvas="'tableau-simplifie-' + graphiqueDataItem.graphInfo.id" :id="'tableau-simplifie-' + graphiqueDataItem.graphInfo.id" class="colonne-soit-graphique-soit-tableau">
          <TableauSimple :panelDouble="false" class="tableau-simplifie-centre" :data="graphiqueDataItem.data" :graphInfo="graphiqueDataItem.graphInfo" />
          <GraphSources :graphInfoProp="graphiqueDataItem.graphInfo" />
        </Exports>
        <EndroitError :erreurs="graphiqueDataItem.data.errors" />
      </TabPanel> -->
        <!-- fin onglet -->

        <TabPanel
          header="Graphique et tableau"
          v-if="
            !(
              graphiqueDataItem.graphInfo.aucunGraph ||
              graphiqueDataItem.graphInfo.aucunTableau
            )
          "
        >
          <div class="flex graphique_et_tableau">
            <!-- colonne graphique -->
            <Exports
              :data="graphiqueDataItem.data"
              :graphInfo="graphiqueDataItem.graphInfo"
              :idCanvas="'graphique-' + graphiqueDataItem.graphInfo.id"
              class="colonnes-dans-graphique-et-tableau"
            >
              <GraphiqueABande
                v-if="graphiqueDataItem.graphInfo.graphType === 'bande'"
                :graphDataConverter="graphDataConverter"
                :graphInfo="graphiqueDataItem.graphInfo"
                :panelDouble="true"
                :graphAxisName="graphiqueDataItem.graphAxisName"
                :data="graphiqueDataItem.data"
                :fieldValue="graphiqueDataItem.fields"
              />
              <GraphiqueACourbe
                v-if="graphiqueDataItem.graphInfo.graphType === 'courbe'"
                :graphInfo="graphiqueDataItem.graphInfo"
                :panelDouble="true"
                :graphAxisName="graphiqueDataItem.graphAxisName"
                :data="graphiqueDataItem.data"
                :fieldValue="graphiqueDataItem.fields"
              />
              <GraphiqueATarte
                v-if="graphiqueDataItem.graphInfo.graphType === 'tarte'"
                :graphInfo="graphiqueDataItem.graphInfo"
                :panelDouble="false"
                :graphAxisName="graphiqueDataItem.graphAxisName"
                :data="graphiqueDataItem.data"
                :fieldValue="graphiqueDataItem.fields"
                :translator="translator"
              />
              <GraphSources
                :graphInfoProp="graphiqueDataItem.graphInfo"
                :data="graphiqueDataItem.data"
              />
            </Exports>

            <!-- colonne tableau -->
            <Exports
              :data="graphiqueDataItem.data"
              :graphInfo="graphiqueDataItem.graphInfo"
              :idCanvas="'tableau-' + graphiqueDataItem.graphInfo.id"
              class="colonnes-dans-graphique-et-tableau"
              @startExport="changeIsExporting(true)"
              @endExport="changeIsExporting(false)"
            >
              <Tableau
                :panelDouble="true"
                :data="graphiqueDataItem.data"
                :graphInfo="graphiqueDataItem.graphInfo"
              />
              <GraphSources
                :graphInfoProp="graphiqueDataItem.graphInfo"
                :data="graphiqueDataItem.data"
                v-if="isExporting"
              />
            </Exports>
          </div>
          <!-- fin div flex graphique_et_tableau -->
          <EndroitError :erreurs="graphiqueDataItem.data.errors" />
        </TabPanel>
        <!-- fin onglet -->
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import GraphiqueABande from './Graphique/GraphiqueABande.vue';
import GraphiqueACourbe from './Graphique/GraphiqueACourbe.vue';
import GraphiqueATarte from './Graphique/GraphiqueATarte.vue';
import GraphiqueStacked from './Graphique/GraphiqueStacked.vue';
import { defineComponent, PropType, reactive } from 'vue';
import { DescriptionGraphiqueDTO } from '../DTO/DescriptionGraphiqueDTO';
import { MenuSectionItem } from '../DTO/MenuSectionItem';
import { ValueTranslator } from '@/traduction/ValueTranslator';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { GraphDataConverter } from '@/helpers/GraphDataConverter';
import { GraphiquesDataGeneratedByGraphicPageDTO } from '@/DTO/GraphiquesDataGeneratedByGraphicPageDTO';
import GraphSources from '@/components/Graphique/GraphSources.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tableau from '@/components/Tableau/Tableau.vue';
import TableauSimple from '@/components/Tableau/TableauSimple.vue';
import Exports from '../components/Exports.vue';
import { GraphiqueAGenererInfoDTO } from '@/DTO/GraphiqueAGenererInfoDTO';
import { GraphErrorDTO } from '@/DTO/GraphErrorDTO';
import EndroitError from '../components/EndroitError.vue';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import SpecialStringHandler from '@/helpers/SpecialStringHandler';
import { TypeTableau } from '@/DTO/Tableau/TypeTableau';
import { array } from '@amcharts/amcharts4/core';

export default defineComponent({
  name: 'RegroupementDeGraphique',
  props: {
    section: {
      type: Object as PropType<MenuSectionItem>,
      required: true
    },
    graphDataList: {
      type: Array as PropType<Array<GraphiqueAGenererInfoDTO>>,
      required: true
    },
    translator: {
      type: ValueTranslator,
      required: true
    },
    endroitSelectionHelper: {
      type: EndroitSelectionHelper,
      required: true
    },
    graphDataConverter: {
      type: GraphDataConverter,
      required: true
    }
  },
  components: {
    GraphiqueABande,
    GraphiqueACourbe,
    GraphSources,
    TabPanel,
    TabView,
    Tableau,
    // TableauSimple,
    Exports,
    EndroitError,
    GraphiqueATarte,
    GraphiqueStacked
  },
  computed: {
    TypeTableau () {
      return TypeTableau;
    }
  },
  data () {
    return {
      graphiquesData: reactive(
        new Array<GraphiquesDataGeneratedByGraphicPageDTO>()
      ),
      isExporting: false
    };
  },
  methods: {
    changeIsExporting (isExporting: boolean) {
      this.isExporting = isExporting;
    },
    updateNewDataFrontEnd (graph: DescriptionGraphiqueDTO) {
      // Les noms des axes
      const graphAxisName = {
        xAxis: graph.XAxixName,
        yAxis: graph.YAxixName
      };
      // On va traduire en langage lisible les "Headers" des données si on a des displayfields de set
      let translatedFieldsValue = new Array<string>();
      if (graph.displayFields !== undefined) {
        translatedFieldsValue = new Array<string>(graph.displayFields.length);
        for (let i = 0; i < graph.displayFields.length; i++) {
          if (graph.displayFields[i] === graph.totalField) {
            translatedFieldsValue[i] = this.translator.translate('total');
          } else {
            translatedFieldsValue[i] = this.translator.translate(
              graph.displayFields[i]
            );
          }
        }
      }

      const endroitsActives =
        this.endroitSelectionHelper.getAllSelectedEndroits();
      const graphData = this.$store.getters['recensement/getGraph'](graph.id);
      const graphErrors = this.$store.getters['recensement/getGraphErrors'](
        graph.id
      ) as Array<GraphErrorDTO>;
      const graphReponsesPossibles = this.$store.getters[
        'recensement/getGraphReponsesPossibles'
      ](graph.id) as Array<string> | undefined;

      const graphErrorAsMap = new Map<string, GraphErrorDTO>();
      graphErrors.forEach((error) => {
        graphErrorAsMap.set(error.endroitData._id, error);
      });
      // On va traduire en langage lisible les valeurs des données
      const translatedGraphData =
        this.graphDataConverter.translateValuesOfDataList(graphData, graph);
      // On réordonne le array par année du plus petit au plus grand
      translatedGraphData.sort(function (a, b) {
        return a.anneeRecensement - b.anneeRecensement;
      });
      // On génère les données selon un objet avec les valeurs comme: endroitId-annee-champ
      const dataConverted = this.graphDataConverter.convertData(
        graph,
        translatedGraphData,
        endroitsActives,
        graphReponsesPossibles
      );
      const dataAsMap = new Map<string, Array<RecensementDataItem>>();
      const globalTotalAsMap = new Map<string, RecensementDataItem>();
      for (const [key, value] of dataConverted.entries()) {
        dataAsMap.set(key, value.data);
        value.errors.forEach((error) => {
          graphErrorAsMap.set(error.endroitData._id, error);
        });
        if (value.globalTotal !== undefined) {
          globalTotalAsMap.set(key, value.globalTotal);
        }
      }

      // Toutes les années dispo
      const anneesRecensements =
        this.graphDataConverter.getAllAnneesRecensements(translatedGraphData);

      // Sépare les champs pour faire que chaque variations de la valeur de splitField ait un graphique
      if (graph.splitField !== undefined && endroitsActives.length > 1) {
        const newDataArrayMap = new Map<string, Map<string, any[]>>();
        const newGlobalTotalAsMap = new Map<string, RecensementDataItem>();
        // Pour chaque endroit
        for (const [key, value] of dataAsMap.entries()) {
          // Pour chaque entrée de donnée du graphique de l'endroit
          let valSearchSplit = value;

          if (graph.questionnaireSources || graph.questionnaireTableauSources) {
            valSearchSplit = value.filter((item) => item[graph.splitField as string] !== 'Date de complétion');
          }
          valSearchSplit.forEach((item) => {
            const itemValue = item[graph.splitField as string];
            // Si c'est une ancienne valeur déjà rencontré, on ajoute la donnée dans la liste de la map
            if (newDataArrayMap.has(itemValue)) {
              const internalItem = newDataArrayMap.get(itemValue)!;
              let internalMapItem = new Array<any>();
              if (internalItem!.has(key)) {
                internalMapItem = internalItem!.get(key)!;
              }
              internalMapItem.push(item);
              internalItem!.set(key, internalMapItem);
              newDataArrayMap.set(itemValue, internalItem);
            } else {
              // Si c'est une nouvelle valeur jamais rencontré, on ajoute une liste de donnée dans la map
              const mapItem = new Map<string, any[]>();
              const arrayItem = new Array<any>();
              arrayItem.push(item);
              mapItem.set(key, arrayItem);
              newDataArrayMap.set(itemValue, mapItem);
            }
            if (globalTotalAsMap.has(key)) {
              newGlobalTotalAsMap.set(
                itemValue,
                globalTotalAsMap.get(key) as RecensementDataItem
              );
            }
          });
        }
        for (const [key, item] of newDataArrayMap.entries()) {
          // Les valeurs minimales et maximales des graphs
          const minData = this.graphDataConverter.getMinTotalValueFromDataArray(
            item,
            graph
          );
          const maxData = this.graphDataConverter.getMaxTotalValueFromDataArray(
            item,
            graph
          );

          const dataToSend = {
            data: item,
            endroits: endroitsActives,
            anneesRecensement: anneesRecensements,
            minData,
            maxData,
            errors: graphErrorAsMap,
            reponsesPossibles: graphReponsesPossibles
          } as FormatedGraphiqueDataItemDTO;
          if (newGlobalTotalAsMap.has(key)) {
            dataToSend.totalGlobal = newGlobalTotalAsMap;
          }
          const newGraphInfo = {
            ...JSON.parse(JSON.stringify(graph))
          } as DescriptionGraphiqueDTO;
          newGraphInfo.name = SpecialStringHandler.replaceParamInString(
            newGraphInfo.name,
            key
          );
          this.graphiquesData.push({
            data: dataToSend,
            fields: translatedFieldsValue,
            graphAxisName,
            graphInfo: newGraphInfo,
            graphId: graph.id
          });
        }
      } else {
        // Les valeurs minimales et maximales des graphs
        const minData = this.graphDataConverter.getMinTotalValueFromDataArray(
          dataAsMap,
          graph
        );
        const maxData = this.graphDataConverter.getMaxTotalValueFromDataArray(
          dataAsMap,
          graph
        );

        const dataToSend = {
          data: dataAsMap,
          endroits: endroitsActives,
          anneesRecensement: anneesRecensements,
          minData,
          maxData,
          errors: graphErrorAsMap,
          reponsesPossibles: graphReponsesPossibles
        } as FormatedGraphiqueDataItemDTO;
        if (globalTotalAsMap.size > 0) {
          dataToSend.totalGlobal = globalTotalAsMap;
        }
        this.graphiquesData.push({
          data: dataToSend,
          fields: translatedFieldsValue,
          graphAxisName,
          graphInfo: graph,
          graphId: graph.id
        });
      }
    }
  },
  created () {
    this.graphDataList.forEach((graph) => {
      if (graph.estABuild) {
        this.updateNewDataFrontEnd(graph.graphInfo);
      }
    });
  },
  mounted () {
    debugger;
    this.$loading.stopLoading();
  }
});
</script>

<style lang="scss">
.colonnes-dans-graphique-et-tableau {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.flex {
  display: flex;
  /* flex-direction: row;
    justify-content: space-between; */
}

.p-tabview .p-tabview-nav li {
  z-index: 1 !important;
}
</style>
