<template>
  <div class="flex-container">
      <div class="loginContainer">
          <form v-on:submit.prevent="login">
            <div class="container">
              <div>
                <div>
                  <img id="logo" src="/images/logo-RURLS.png" alt="">
                </div>
                <div>
                  <h1>Bienvenue!</h1>
                </div>
                <div>
                  <h2>Connexion</h2>
                </div>
                <div class="error" v-if="error">
                  {{error}}
                </div>
                <div>
                  <label for="email">Adresse courriel</label>
                  <input type="text" placeholder="Entrez l'adresse courriel" name="email" v-model="email" required>
                </div>
                <div>
                  <label for="password">Mot de passe</label>
                  <input type="password" toggleMask="true" placeholder="Entrez le mot de passe" name="password" v-model="password" required>
                </div>
                <div>
                  <button class="boutonbleu" @click="login" type="submit">Se connecter</button>
                </div>
                <div>
                  <a class="forgot-password" href="/forgot-password">Mot de passe oublié?</a>
                </div>
              </div>
            </div>
          </form>
      </div>
      <div class="right-side">
        <img src="../../public/images/fond-reseau.png"/>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Login',
  data () {
    return {
      error: '',
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      const token = await this.$recaptcha('login')
      this.error = '';

      if (!this.email) {
        this.error = 'Adresse courriel requise.';
      } else if (!this.validEmail(this.email)) {
        this.error = 'Adresse courriel invalide.';
      }
      if (!this.password && !this.error) {
        this.error = 'Mot de passe requis.';
      }

      const loginBody = {
        email: this.email,
        password: this.password,
        token
      };
      if (!this.error) {
        this.$store.dispatch('auth/login', loginBody).then(() => {
          localStorage.setItem('email', loginBody.email);
          location.reload();
        })
          .catch(() => {
            this.error = 'L’adresse courriel ou le mot de passe entré est incorrect.';
          });
      }
    },
    validEmail: function (email: string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
})
</script>

<style scoped>

#app {
  height:100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  height:100%;
  width:100%;
}

html {
  height:100%;
}

.flex-container {
  display:flex;
  flex-direction: row;
  background-color:white;
  height:100%;
}

.error{
  color:red;
  background-color: #FFEDED;
  width:100%;
  font-size:14px;
  font-weight: 400;
  text-align:center;
  margin-bottom:15px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

a{
  color: #5C72AF;
  font-size:14px;
  font-weight: 400;
  cursor: pointer;
}

.loginContainer {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-side {
  width: 65%;
  background-image: linear-gradient(to left,#001B33,#001B33C9,#38A295);
  opacity:1;
}

.right-side img {
  width: 88%;
  height: 99.63%;
  object-fit: cover;
  float:right;
}

.gradient{
  width:27%;
  background: transparent linear-gradient(0deg, var(--unnamed-color-ffffff) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

form {
    background: white;
    width: 100%;
    height:100%;

    display: flex;
    align-items: center;
}

label{
  font-size:14px;
  color:#2A4DA1;
  font-weight: 500;
}
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: 400;
}

img#logo{
  width: 70%;
  margin: 0 auto ;
}

h1{
  font-size: 36px;
  letter-spacing: 0.15px;
  color: #2A4DA1;
  margin-top: 15%;
  margin-bottom: 15%;
  width:100%;
  font-weight:400;
}

h2{
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  font-weight:600;
  letter-spacing: 0.15px;
}

/* Set a style for all buttons */
button {
  background : #F0753B 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 4px;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  font-size:14px;
}

button:hover {
  background: rgba(208, 84, 25, 1);
}

input{
    border: 0 !important;
    outline: 0;
    border-bottom: 1px solid #4F5866 !important;
    text-align:center;
    margin-top:7.5%;
    margin-bottom:7.5%;
}
/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  background: white;
  padding: 16px;
  margin: 0 auto;
  height: 80%;
  width: 80%;
  display:flex;
  flex-direction: column;
  position: relative;
}

.container > div {
  margin: auto;
}

@media screen and (max-width: 768px) {
  form {
    width: auto;
    background: none;
  }

  .container {
    width: 70vw;
  }

  .flex-container {
    display: flex;
    justify-content: center;

    background: transparent url(../../public/images/fond-reseau.png) 0% 0% no-repeat;
    background-size: cover;
    background-position: center;
  }

  /*  */
  .right-side {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  @media screen and (min-width: 769px) {
    form {
      background: white;
    }
  }
}

</style>
