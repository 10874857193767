<template>
    <div class="filtre">
      <span class="titreFiltre">Municipalités recherchées</span>
      <hr/>
      <div class="filtreEndroitContainer">
        <div class="filtreEndroitItemsContainer">
          <div class="btnEndroit" @click="toggleEndroit(index)" v-for="(end, index) in endroits" v-bind:class="endroitsDesactives.includes(index) ? 'disabled' : 'enabled'" :key="end.NOM_GEO">
            <span class="btnEndroitNomEndroit">{{ end.NOM_GEO }}</span>
            <!-- Le bouton X n'est qu'un + ayant subi une rotation. La librairie ne comportait pas de X. La librairie Prime Icons ne comportait pas de X. -->
            <span @click.stop="remove(end, index)" class="icon"><i class="pi pi-plus rotation-icone-plus"></i></span>
          </div>
        </div>
        <div class="filtreEndroitBtnUtilsContainer">
          <button class="boutonbleu" @click="btnAction.action">{{ btnAction.displayText }}</button>
          <button class="boutonbleu" @click="supprimerTout()">Supprimer tout</button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { EndroitDTO } from '@/DTO/EndroitDTO';
import { defineComponent, inject, Ref } from 'vue'
import { ActionBtn } from '../DTO/ActionBtn';
import { EndroitSelectionHelper } from '../helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'EndroitsSelectionnes',
  props: {
    btnAction: {
      type: ActionBtn,
      required: true
    }
  },
  emits: [
    'btnEndroitPressed'
  ],
  methods: {
    conversionStringANum (val: string) {
      return Number.parseInt(val);
    },
    toggleEndroit (id: number) {
      if (this.endroitsDesactives.includes(id)) {
        this.endroitSelectionHelper.activateEndroit(id.toString()).then(() => {
          this.$emit('btnEndroitPressed')
        });
      } else {
        this.endroitSelectionHelper.deactivateEndroit(id.toString()).then(() => {
          this.$emit('btnEndroitPressed')
        });
      }
    },
    remove (end: EndroitDTO, id: number) {
      this.endroitSelectionHelper.removeEndroit(end, id).then(() => {
        this.$emit('btnEndroitPressed')
      });
    },
    supprimerTout () {
      this.endroitSelectionHelper.removeAllEndroit().then(() => {
        this.endroits = [];
      });
    }
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitsDesactives = inject('endroitsDesactives') as Ref<Array<number>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitsDesactives,
      endroitSelectionHelper
    }
  }
})

</script>

<style lang="scss" scoped>
  .titreFiltre{
    font-weight: 600;
    text-transform: uppercase;
  }

  .filtre {
    text-align:left;
  }

  .btnEndroit{
    border-style: none;
    border-radius: 4px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer !important;
    font-size: 13px;
    padding: 10px;
    height: 36px;
    display: flex;
    flex-direction: row;
    .btnEndroitNomEndroit {
      margin-right: 5px;
    }
  }
  .enabled{
    background-color: #DCF5F2;
    color: #38A295;
  }

  .disabled{
    background-color: #F2F2F2;
    color: #777777;
  }

  .filtreEndroitContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .filtreEndroitBtnUtilsContainer {
    display: flex;
    flex-direction: column;
    button {
      white-space: nowrap;
      margin: 5px;
      width: 230px;
      height: 36px;
    }
  }

  .filtreEndroitItemsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .rotation-icone-plus {
    // Pour que le symbole/l'icône + subisse une rotation et devienne un X, comme il n'y a pas d'icône de ce type autrement dans PrimeVue.
    transform: rotate(45deg);
  }
</style>
