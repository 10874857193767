import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Questionnaire from '../views/Questionnaire.vue';
import Uploader from '../views/Uploader.vue';
import ResetPassword from '../views/PasswordReset.vue';
import { jwtDecode } from 'jwt-decode';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home/:section',
    name: 'Home',
    component: Home,
    meta: {
      isGraph: true
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot-Password',
    component: ForgotPassword
  },
  {
    path: '/uploader',
    name: 'Uploader',
    component: Uploader,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/resetPassword/:resetToken',
    name: 'Reset-Password',
    component: ResetPassword
  },
  {
    path: '/rechercherEndroit/:section',
    name: 'Rechercher-Endroit',
    component: Home,
    meta: {
      isGraph: false
    }
  },
  {
    path: '/questionnaire/:id',
    name: 'Questionnaire',
    component: Questionnaire
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
  },
  routes
});

router.beforeEach((to: any, from: any, next: any) => {
  let isLoggedIn = false;
  const token = localStorage.getItem('token') as any;
  // Si l'utilisateur n'est pas connecté.

  if (token !== undefined && token != null && token !== '') {
    const decoded = jwtDecode(token);
    const expireDateTime = decoded.exp;

    if (expireDateTime !== undefined) {
      if (Date.now() >= expireDateTime * 1000) {
        localStorage.removeItem('token');
        isLoggedIn = false;
      } else {
        isLoggedIn = true;
      }
    } else {
      localStorage.removeItem('token');
      isLoggedIn = false;
    }
  }

  if (to.meta.requiresLogin && !isLoggedIn) {
    next({ name: 'Login' });
    // Si l'utilisateur est connecté.
  } else if (to.name === 'Login' && isLoggedIn) {
    next({ name: 'Home', params: { section: 'profil-demographique' } });
    // Si l'utilisateur change de page, on veut garder les paramètres de la requête.
  } else if (
    from.query.endroits !== undefined &&
    to.query.endroits === undefined &&
    to.query.keepEndroits !== undefined
  ) {
    to.query.endroits = from.query.endroits;
    to.query.endroitsDesactives = from.query.endroitsDesactives;
    delete to.query.keepEndroits;
    next(to);
  } else if (to.name === 'Home' && to.query.endroits === undefined) {
    next({
      name: 'Rechercher-Endroit',
      params: { section: to.params.section }
    });
    // Si l'utilisateur active/désactive un carré coloré correspondant à une municipalité, mais sans changer de page.
  } else {
    next();
  }
});

export default router;
