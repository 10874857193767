<template>
  <div class="filtre">
    <span class="titreFiltre">RECHERCHER PAR RÉPONDANT AUX QUESTIONNAIRES</span>
    <hr/>
    <div class="inputFiltre">
      <Dropdown class="inputFiltre-filtre" v-model="questionnaireIndex" :options="['Bilan de santé','Municipalité','Organisation']" placeholder="Questionnaire" @change="questionnaireChange()"></Dropdown>
      <Dropdown v-if="questionnaireIndex != null" ref="dropdown" class="inputFiltre-filtre" v-model="municipaliteSelectionnee" :options="dropdownDataMunicipalite" optionLabel="displayName" dataKey="_id" :autoFilterFocus="true" :resetFilterOnHide="true" emptyFilterMessage="Aucun résultat ne correspond à votre requête." placeholder="Région, MRC, Municipalité" :filter="true">
      <template #option="slotProps">
        <div style="display:flex; justify-content: space-between; flex-direction: row;">
          <div style="white-space:break-spaces; width:60%;">
            {{slotProps.option.displayName}}
          </div>
        </div>
      </template>
      </Dropdown>
      <button @click="ajouterMunicipalite" class="bouton">Ajouter <span class="icon">
        <i class="pi pi-plus"></i>
      </span></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'RechercheEndroitParRepondant',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataMunicipalite: new Array<any>(),
      questionnaireIndex: null,
      repondantParQuestionnaire: new Array<any>()
    }
  },
  methods: {
    async ajouterMunicipalite () {
      const endroitSelectionnee = this.municipaliteSelectionnee;
      if (endroitSelectionnee !== null) {
        this.$loading.startLoading();
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      } else if (this.questionnaireIndex !== null) {
        this.$loading.startLoading();
        this.endroitSelectionHelper.addMultipleEndroits(this.dropdownDataMunicipalite.map(end => end._id)).then(() => {
          this.$toast.success('Les endroits ont bien été ajoutés.');
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData (endroit: any) {
    },
    questionnaireChange () {
      this.dropdownDataMunicipalite = [];
      if (this.questionnaireIndex === 'Bilan de santé') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q1 === true) {
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      } else if (this.questionnaireIndex === 'Municipalité') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q2 === true) {
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      } else if (this.questionnaireIndex === 'Organisation') {
        if (this.repondantParQuestionnaire != null) {
          this.repondantParQuestionnaire.forEach((element: any) => {
            if (element.q3 === true) {
              const endroit = this.endroitsData.find((endroit: any) => endroit.slug === element.slug);
              this.dropdownDataMunicipalite.push(endroit);
            }
          });
        }
      }
      this.displayNameAttributName();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    },
    displayNameAttributName () {
      this.dropdownDataMunicipalite.forEach(element => {
        if (element.displayName === undefined) {
          element.displayName = element.NOM_GEO + ' (municipalité) - ' + element.NOM_MRC;
        }
      });
    }
  },
  mounted () {
    this.$store.dispatch('questionnaire/getEndroitRepondant', { questionnaireIndex: this.questionnaireIndex }).then((response) => {
      this.repondantParQuestionnaire = response;
    })
  }
})

</script>

<style lang="scss" scoped>
  .titreFiltre {
    font-weight: 600;
    text-transform: uppercase;
  }

  .filtre {
    text-align:left;
  }

  /* Règle width: 50% à l'origine. Modifié par du flex. Cette règle ne semblait apporter aucun changement visuel. */
  .inputFiltre {
    display: flex;
    flex-wrap: wrap;
    &-filtre {
      width: 25%;
      margin-right: 2%;
    }
  }

  .bouton {
    border: none;
    border-radius: 4px;
    background: #5C72AF;
    color: white;
    font-weight: 700;
    // Pour que le bouton conserve sa hauteur, même en mode mobile, quand il est dessous la barre de recherche
    height: 37.25px;
    width: 10%;
    position: relative;
    // Pour uniformiser la transition sur les boutons. Il y en avait une sur celui-ci, mais pas sur le reste.
    transition: none;

    &:hover {
      background: #2A4DA1;
      cursor: pointer;
    }
  }
</style>
