<template>
  <div class="filtre">
    <span class="titreFiltre">Rechercher par critères</span>
    <hr />
    <div class="inputFiltre">
      <div class="inputFiltre-content">
        <div v-for="crit in criteres" :key="crit.champ" class="critere">
          <span class="critere-nom">{{ crit.nom }}:</span>
          <div class="critere-input" v-if="crit.type == 'range'">
            <span class="critere-input-text">Entre</span>
            <div style="display: inline" @click="clickInput(crit)">
              <InputNumber :disabled="!crit.active" v-model="crit.value.min" />
            </div>
            <span class="critere-input-text">et</span>
            <div style="display: inline" @click="clickInput(crit)">
              <InputNumber :disabled="!crit.active" v-model="crit.value.max" />
            </div>
            <Checkbox
              class="critere-input-chk"
              :binary="true"
              v-model="crit.active"
              @change="updateBtnRecherche"
            />
          </div>
          <div class="critere-input" v-else-if="crit.type == 'select'">
            <div style="display: inline" @click="clickInput(crit)">
              <Dropdown
                :options="crit.options"
                optionLabel="label"
                optionValue="value"
                placeholder="Sélectionner..."
                v-model="crit.value.selected"
              />
            </div>
            <Checkbox
              class="critere-input-chk"
              :binary="true"
              v-model="crit.active"
              @change="updateBtnRecherche"
            />
          </div>
          <div class="critere-input" v-else-if="crit.type == 'select-endroit'">
            <div style="display: inline" @click="clickInput(crit)">
              <Dropdown
                v-if="crit.champ === 'noRegion'"
                :options="crit.optionsEndroit"
                optionLabel="NOM_GEO"
                optionValue="noEndroitCarte"
                placeholder="Sélectionner..."
                v-model="crit.value.selected"
                emptyFilterMessage="Aucun résultat ne correspond à votre requête."
                :filter="true"
                :autoFilterFocus="true"
                :resetFilterOnHide="true"
              />
              <Dropdown
                v-else-if="crit.champ === 'noParent'"
                :options="crit.optionsEndroit"
                optionLabel="NOM_GEO"
                optionValue="noEndroit"
                placeholder="Sélectionner..."
                v-model="crit.value.selected"
                emptyFilterMessage="Aucun résultat ne correspond à votre requête."
                :filter="true"
                :autoFilterFocus="true"
                :resetFilterOnHide="true"
              />
            </div>
            <Checkbox
              class="critere-input-chk"
              :binary="true"
              v-model="crit.active"
              @change="updateBtnRecherche"
            />
          </div>
        </div>
        <div>
          <button
            @click="rechercherCriteres"
            :disabled="!btnRechercheEnabled"
            class="bouton"
          >
            Rechercher
            <i class="pi pi-chevron-right" />
          </button>
        </div>
      </div>
    </div>
    <Dialog
      header="Résultat de la recherche"
      v-model:visible="showResultatsRecherche"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '75vw' }"
    >
      <div class="container-button">
        <button @click="selectionnerEndroits" class="boutonbleu">
          Sélectionner
          <i class="pi pi-chevron-right" />
        </button>
      </div>
      <table class="resultat">
        <tr>
          <th style="text-align: center">
            <Checkbox
              v-model="selectionneAll"
              :binary="true"
              @click="selectionnerAllEndroits"
            ></Checkbox>
          </th>
          <th>Nom</th>
          <th v-for="crit in critereActive" :key="crit.champ">
            {{ crit.nom }}
          </th>
        </tr>
        <tr v-for="endroit in resultatsRecherche" :key="endroit._id">
          <td style="text-align: center">
            <Checkbox
              v-model="endroit.selectionne"
              :binary="true"
              @change="cancelSelectionneAll()"
            />
          </td>
          <td>{{ endroit.NOM_GEO }}</td>
          <td v-for="crit in critereActive" :key="crit.champ">
            {{ getEndroitCritere(endroit, crit) }}
          </td>
        </tr>
      </table>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from 'vue';
import { EndroitDTO } from '../DTO/EndroitDTO';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import { EndroitType } from '@/DTO/EndroitType';
// import { array } from '@amcharts/amcharts4/core';
export default defineComponent({
  name: 'RechercheEndroitCriteres',
  emits: ['onEndroitUpdated'],
  components: {
    InputNumber,
    Dropdown,
    Dialog,
    Checkbox
  },
  props: {
    endroitsData: {
      type: Array as PropType<Array<EndroitDTO>>,
      required: true
    }
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>;
    const endroitSelectionHelper = inject(
      'endroitSelectionHelper'
    ) as Ref<EndroitSelectionHelper>;
    return {
      endroits,
      endroitSelectionHelper
    };
  },
  data () {
    return {
      selectionneAll: false,
      showResultatsRecherche: false,
      btnRechercheEnabled: false,
      resultatsRecherche: new Array<EndroitDTO>(),
      criteres: [
        {
          nom: "NOMBRE D'HABITANTS",
          champ: 'populationTotal',
          unite: 'hab.',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'SUPERFICIE EN KM²',
          champ: 'superficieKm2',
          unite: 'km2',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'DENSITÉ DE POPULATION AU KM²',
          champ: 'densiteKm2',
          unite: 'hab./km2',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'TAUX DE FAIBLE REVENU',
          champ: 'tauxFaibleRevenue',
          unite: '%',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'RICHESSE FONCIÈRE UNIFORMISÉE',
          champ: 'rfu',
          unite: '$',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'TAUX GLOBAL DE TAXATION UNIFORMISÉ',
          champ: 'tauxGlobalTaxation',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'ENDETTEMENT À LONG TERME PAR 100 $ DE RFU',
          champ: 'endettement100rfu',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'CHARGES MUNICIPALES PAR 100 $ ',
          champ: 'charge100rfu',
          unite: '',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'DÉPENSES DE FONCTIONNEMENT',
          champ: 'depenseTotal',
          unite: '$',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'POURCENTAGE DES DÉPENSES EN LOISIRS ET CULTURE',
          champ: 'pourcentageDepenseLoisirEtCulture',
          unite: '%',
          type: 'range',
          active: false,
          value: {
            min: 0,
            max: 0
          }
        },
        {
          nom: 'POSTE BUDGÉTAIRE EN LOISIR LE PLUS ÉLEVÉ',
          champ: 'postePlusEleve',
          unite: '',
          type: 'select',
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'p6047',
              label: 'Centres communautaires'
            },
            {
              value: 'p6048',
              label: 'Patinoires intérieures et extérieures'
            },
            {
              value: 'p6049',
              label: 'Piscines, plages et ports de plaisance'
            },
            {
              value: 'p6050',
              label: 'Parcs et terrains de jeux'
            },
            {
              value: 'p6071',
              label: 'Parcs régionaux'
            },
            {
              value: 'p6051',
              label: 'Expositions et foires'
            },
            {
              value: 'p6052',
              label: 'Autres activités récréatives'
            },
            {
              value: 'p6054',
              label: 'Centres communautaires (activité récréative)'
            },
            {
              value: 'p6055',
              label: 'Bibliothèques'
            },
            {
              value: 'p6056',
              label: "Musées et centres d'exposition"
            },
            {
              value: 'p6057',
              label: 'Autres ressources du patrimoine'
            },
            {
              value: 'p6058',
              label: 'Autres activités culturelles'
            }
          ]
        },
        {
          nom: 'ENTENTE SCOLAIRE',
          champ: 'quest1EntenteScolaire',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: 'VILLAGE-RELAIS',
          champ: 'quest1VillageRelais',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: 'CONSULTATION PUBLIQUE RÉCENTE (MOINS DE 3 ANS)',
          champ: 'quest1ConsultationPublique',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: 'ENTENTE INTERMUNICIPALE',
          champ: 'quest1EntenteIntermunicipal',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: 'COMITÉ LOISIR',
          champ: 'quest1ComiteLoisir',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: "SYSTÈME D'INSCRIPTION EN LIGNE",
          champ: 'quest1SystemeInscription',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        },
        {
          nom: 'INFRASTRUCTURE MAJEURE (PISCINE,CENTRE-MULTI-SPORT, ETC.)',
          champ: 'quest1InfastructureMajeure',
          type: 'select',
          unite: '',
          isQuestionnaire: true,
          active: false,
          value: {
            selected: null,
            min: 0,
            max: 0
          },
          options: [
            {
              value: 'Oui',
              label: 'Oui'
            },
            {
              value: 'Non',
              label: 'Non'
            },
            {
              value: 'C’est compliqué / je ne sais pas',
              label: 'C’est compliqué / je ne sais pas'
            }
          ]
        }
        // {
        //   nom: 'Nombre employé en loisir',
        //   champ: 'nombreEmployeLoisir',
        //   unite: '',
        //   type: 'range',
        //   active: false,
        //   value: {
        //     min: 0,
        //     max: 0
        //   }
        // }
      ]
    };
  },
  computed: {
    critereActive: function () {
      return this.criteres.filter((x) => x.active === true);
    }
  },
  methods: {
    clickInput (crit: any) {
      crit.active = true;
      this.updateBtnRecherche();
    },
    getEndroitCritere (endroit: EndroitDTO, crit: any) {
      let value = endroit[crit.champ];
      if (
        crit.champ === 'populationTotal' ||
        crit.champ === 'depenseLoisirEtCulture' ||
        crit.champ === 'depenseTotal' ||
        crit.champ === 'rfu'
      ) {
        value = endroit[crit.champ]
          .toFixed(0)
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ');
      }
      if (
        crit.champ === 'pourcentageDepenseLoisirEtCulture' ||
        crit.champ === 'tauxFaibleRevenue'
      ) {
        value = parseFloat(endroit[crit.champ]).toFixed(1);
      }

      if (crit.champ === 'endettement100rfu' || crit.champ === 'charge100rfu') {
        value = (parseFloat(endroit[crit.champ]) * 100).toFixed(2);
      }

      if (crit.champ === 'tauxGlobalTaxation') {
        value = parseFloat(endroit[crit.champ]).toFixed(2);
      }
      if (crit.type === 'select') {
        if (crit.isQuestionnaire === true) {
          value = value.reponse;
          // value = crit.options.find((element:any) => element.value.reponse === crit.value.selected);
        } else {
          value = crit.options.find(
            (element: any) => element.value === crit.value.selected
          ).label;
        }
      }
      if (crit.type === 'select-endroit') {
        if (crit.champ === 'noRegion') {
          value = crit.optionsEndroit.find((element: any) => {
            return element.noEndroitCarte === crit.value.selected;
          }).NOM_GEO;
        } else if (crit.champ === 'noParent') {
          value = crit.optionsEndroit.find((element: any) => {
            return element.noEndroit === crit.value.selected;
          }).NOM_GEO;
        }
      }
      if (value === undefined) {
        return 'N/A';
      } else {
        return value + ' ' + crit.unite;
      }
    },
    updateBtnRecherche () {
      this.btnRechercheEnabled = this.criteres.some((crit) => crit.active);
    },
    cancelSelectionneAll () {
      this.selectionneAll = false;
    },
    selectionnerEndroits () {
      this.endroitSelectionHelper
        .addMultipleEndroits(
          this.resultatsRecherche
            .filter((endroit) => endroit.selectionne)
            .map((end) => end._id)
        )
        .then(() => {
          this.$toast.success('Les endroits ont bien été ajoutés.');
          this.$emit('onEndroitUpdated');
          this.showResultatsRecherche = false;
        });
    },
    selectionnerAllEndroits () {
      this.selectionneAll = !this.selectionneAll;
      this.resultatsRecherche.forEach((element) => {
        element.selectionne = !element.selectionne;
      });
    },
    rechercherCriteres () {
      this.showResultatsRecherche = true;

      this.resultatsRecherche = this.endroitsData
        .filter((endroit) => {
          return (
            endroit.typeEndroit === EndroitType.municipalite &&
            this.criteres.every((crit) => {
              if (!crit.active) {
                return true;
              } else if (crit.champ in endroit) {
                const valeur = (endroit as any)[crit.champ];
                if (crit.type === 'range' && valeur > 0) {
                  return (
                    valeur >= crit.value.min &&
                    (valeur <= crit.value.max ||
                      (crit.value.min !== 0 && crit.value.max === 0))
                  );
                } else if (crit.type === 'select') {
                  if (crit.isQuestionnaire) {
                    return valeur.reponse === crit.value.selected;
                  } else {
                    return valeur === crit.value.selected;
                  }
                } else if (crit.type === 'select-endroit') {
                  if (crit.champ === 'noRegion') {
                    return valeur === crit.value.selected;
                  } else if (crit.champ === 'noParent') {
                    return valeur === crit.value.selected;
                  }
                }
              }
              return false;
            })
          );
        })
        .map((endroit) => {
          return { ...endroit, selectionne: false };
        });
      this.resultatsRecherche.sort((a, b) => {
        if (a.NOM_GEO === b.NOM_GEO) {
          return 0;
        } else if (a.NOM_GEO < b.NOM_GEO) {
          return -1;
        } else {
          return 1;
        }
      });
      // this.$store.dispatch('endroit/getEndroitsByCriteres', criteres).then((result) => {
      // })
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1;
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },
  mounted () {
    var dropdownDataMRC = this.getSortedEndroitArray(
      this.endroitsData.filter((endr) => {
        return (
          !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.MRC
        );
      })
    );

    var mrcCrit = {
      nom: 'MRC',
      champ: 'noParent',
      type: 'select-endroit',
      unite: '',
      isQuestionnaire: false,
      active: false,
      value: { selected: null, min: 0, max: 0 },
      options: [],
      optionsEndroit: dropdownDataMRC
    };

    // // @ts-expect-error donbin tannant typescript
    this.criteres.unshift(mrcCrit);

    var dropdownDataRegion = this.getSortedEndroitArray(
      this.endroitsData.filter((endr) => {
        return (
          !this.endroits.includes(endr) &&
          endr.typeEndroit === EndroitType.Region
        );
      })
    );

    var regionCrit = {
      nom: 'RÉGION ADMINISTRATIVE',
      champ: 'noRegion',
      type: 'select-endroit',
      unite: '',
      isQuestionnaire: false,
      active: false,
      value: { selected: null, min: 0, max: 0 },
      options: [],
      optionsEndroit: dropdownDataRegion
    };

    // // @ts-expect-error donbin tannant typescript
    this.criteres.unshift(regionCrit);
  }
});
</script>

<style lang="scss" scoped>
.resultat {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #38a295;
    color: white;
    position: sticky;
    top: 50px;
    z-index: 10;
  }
}
.container-button {
  z-index: 10;
  background-color: white;
  height: 60px;
  top: 0px;
  position: sticky;
}
.titreFiltre {
  font-weight: 600;
  text-transform: uppercase;
}

.filtre {
  text-align: left;
}

.inputFiltre {
  display: flex;
  flex-wrap: wrap;
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .critere {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      &-nom {
        font-weight: 600;
        margin-right: 15px;
      }
      &-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5px;
        &-text {
          margin-left: 10px;
          margin-right: 10px;
        }
        &-chk {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
.chkBxActive {
  margin-right: 10px;
}

.boutonbleu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  i {
    margin-left: 5px;
  }
}

.bouton {
  border: none;
  border-radius: 4px;
  background: #5c72af;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &:disabled {
    background: #c4c4c4;
  }
  &:hover:enabled {
    background: #2a4da1;
    cursor: pointer;
  }
}
</style>
