<template>
    <div class="card">
        <div class="container">
            <h1>Formulaire sauvegardé</h1>
            <p>Le questionnaire a été envoyé avec succès.</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  components: {
  }
})
</script>

<style scoped lang="scss">
.card {
  /* Add shadows to create the "card" effect */
    background: white;
    border-radius: 10px;
    margin: 1vh 2.5vw;
    padding: 2.5%;
    text-align: left;
    width: 95%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}
</style>
