<template>
  <div class="graph" ref="chartdiv"></div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import couleurs from '@/configs/couleurs.json';
import am4themesKelly from '@amcharts/amcharts4/themes/kelly';
import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { GraphErrorDTO } from '@/DTO/GraphErrorDTO';

function am4themesMyTheme (target: any) {
  // On crée le spectre de couleurs pour le chart avec les couleurs dans le fichier de config
  if (target instanceof am4core.ColorSet) {
    target.list = couleurs.map(couleur => am4core.color(couleur));
  }
}

export default defineComponent({
  name: 'GraphiqueACourbe',
  props: {
    data: {
      type: Object as PropType<FormatedGraphiqueDataItemDTO>,
      required: true
    },
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    },
    panelDouble: Boolean,
    fieldValue: Array,
    graphAxisName: Object
  },
  data () {
    return {
      chart: {} as am4charts.XYChart,
      chartData: {} as Map<string, Array<RecensementDataItem>>,
      errors: {} as Map<string, GraphErrorDTO>
    }
  },
  methods: {
    createGraph () {
      am4core.options.autoSetClassName = true;
      (this.$refs.chartdiv as HTMLElement).innerHTML = '';
      const targetDiv = document.createElement('div') as HTMLDivElement;
      let heightMultiplier = 1;
      if (this.graphInfo.heightMultiplier !== undefined) {
        heightMultiplier = this.graphInfo.heightMultiplier;
      }
      targetDiv.style.minHeight = (600 * heightMultiplier) + 'px';
      // targetDiv.style.width = '100%';
      (this.$refs.chartdiv as HTMLElement).appendChild(targetDiv)
      const chart = am4core.create(targetDiv, am4charts.XYChart);
      // Pour empêcher que le dernier libellé de l'axe des X soit tronqué en fin de ligne et devienne partialement invisible. Voir billets 631 (condanmné en raison d'un bogue étrange de Git) et 646 à ce sujet au besoin. Si on désire changer cela dans GraphiqueABande.vue, il serait logique de changer aussi GraphiqueACourbe.vue.
      chart.paddingRight = 30;
      chart.data = Array.from(this.chartData.values()).flat();
      // création et configuration de la légende
      chart.legend = new am4charts.Legend();
      chart.maskBullets = false;

      chart.zoomOutButton.disabled = true;

      chart.language.locale._thousandSeparator = ' ';

      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      let categoryFieldName = 'anneeRecensement'
      if (this.graphInfo.themeField) {
        categoryFieldName = this.graphInfo.themeField;
      }
      categoryAxis.dataFields.category = categoryFieldName;
      categoryAxis.startLocation = 0.35;
      categoryAxis.endLocation = 0.65;

      // Pour cacher les titres des tableaux sur l'axe des Y, à la demande de la cliente. Passage commenté plutôt qu'effacé au cas où la cliente changerait d'avis.
      // categoryAxis.title.text = (this.graphAxisName?.yAxis as string);
      // categoryAxis.title.fontSize = 16;

      categoryAxis.renderer.minGridDistance = 10;
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Pour cacher les titres des tableaux sur l'axe des X, à la demande de la cliente. Passage commenté plutôt qu'effacé au cas où la cliente changerait d'avis.
      // valueAxis.title.text = (this.graphAxisName?.xAxis as string);
      // valueAxis.title.fontSize = 16;

      valueAxis.extraMax = 0.1
      // valueAxis.renderer.labels.template.width = 87.5;
      valueAxis.renderer.labels.template.width = 90;
      valueAxis.renderer.labels.template.wrap = true;

      // Pour cacher ou non les libellés de l'axe des Y. C'est par exemple ce qu'on fait dans le graphique à courbes 7.
      if (this.graphInfo.cacheLibellesAxeDesX) {
        valueAxis.renderer.labels.template.disabled = true;
      }

      valueAxis.renderer.labels.template.truncate = false;
      valueAxis.calculateTotals = true;
      valueAxis.numberFormatter = new am4core.NumberFormatter();

      /**
       * Format des nombres.
       *
       * Avant, ce qu'on avait comme format ci-dessous était '#a'. De cette manière, on n'avait pas la séparation des milliers. Il a donc fallu changer cela.
       *
       * La raison pour laquelle la virgule ci-dessous est convertie en espace est la présence de cette ligne de code, plus haut:
       *
       * chart.language.locale._thousandSeparator = ' ';
       *
       * https://www.amcharts.com/docs/v4/concepts/formatters/formatting-numbers/#Negative_values_and_zeros
       */
      valueAxis.numberFormatter.numberFormat = '#,##a';

      /**
       * Suffixes pour les grands nombres.
       *
       * 300 000 000 ===> 300 M
       *
       * https://www.amcharts.com/docs/v4/tutorials/modifying-big-number-prefixes/
       */
      valueAxis.numberFormatter.bigNumberPrefixes = [
        { number: 1e+6, suffix: ' M' },
        { number: 1e+9, suffix: ' B' }
      ];
      const title = chart.titles.create();

      // c'est ici que ça se passe pour le style des titres
      // si on modifie les titres dans GraphiqueABande.vue, il faut le faire aussi dans GraphiqueACourbe.vue pour que l'appli soit uniforme
      // le secret est d'utiliser la propriété .maxWidth, qui doit être une taille fournie en pixels
      title.text = '[bold #495057]' + (this.graphInfo.name as string) + '[/]';
      title.fontSize = 25;
      title.marginBottom = 30;
      title.wrap = true;
      title.truncate = false;
      title.maxHeight = 500;
      title.textAlign = 'middle';
      // Pour que le title.wrap s'applique, quand la fenêtre change ça re-dessine le title
      chart.events.on('sizechanged', function () {
        title.deepInvalidate()
      });

      if (this.data.minData === undefined || this.data.maxData === undefined) {
        valueAxis.min = 0;
        valueAxis.max = 100;
      }

      categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.maxWidth = 200;
      this.setSpacingParameters(chart, title);
      this.chart = chart;
    },
    /*
      Setup les paramètre d'un graphiques pour l'espacement de celui-ci
    */
    setSpacingParameters (chart: am4charts.XYChart, title: am4core.Label) {
      chart.height = am4core.percent(100);
      title.marginBottom = 5;
    },
    generateGraphs () {
      this.createGraph();
      this.createSeries();
    },
    createSeries () {
      const chart = this.chart;
      const showPourcentage = this.graphInfo.pourcentageGraph;
      const showDollarSign = this.graphInfo.showDollarSign;
      for (const dataItem of this.chartData.values()) {
        if (dataItem.length > 0 && 'endroit' in dataItem[0]) {
          let nomChampSerie = 'nomEndroit';
          if (this.graphInfo.seriesBy) {
            nomChampSerie = this.graphInfo.seriesBy;
          }
          const series = chart.series.push(new am4charts.LineSeries());
          const name = dataItem[0][nomChampSerie];
          series.name = name;
          series.data = dataItem;
          let categoryName = 'anneeRecensement'
          if (this.graphInfo.themeField !== undefined) {
            categoryName = this.graphInfo.themeField;
          }
          series.dataFields.categoryX = categoryName;

          // Création des valeurs pour l'axe des Y
          if (this.graphInfo.pourcentageAxis) {
            series.dataFields.valueY = 'pourcentage';
          } else {
            series.dataFields.valueY = 'total';
          }

          if (this.graphInfo.showLibellesLegendeSousGraphique === false) {
            series.hiddenInLegend = true;
          }
          series.strokeWidth = 3;
          const circleBullet = series.bullets.push(new am4charts.CircleBullet());

          /**
           * création de l'infobulle (= celle qu'on voit au survol d'une puce du graphique et non celle qu'on voit au survol de la puce d'une barre verticale de moyenne)
           *
           * - {bulletText} = texte formaté
           * - {valueX} = texte non formaté. Par exemple: "1 790.90" versus "1 790.9" ou "90 $" versus "90".
           * - {pourcentageLabel} = nombre arrondi avec symbole de pourcentage
           *
           */
          if (this.graphInfo.pourcentageGraph) {
            if (this.graphInfo.contenuUnitesDansLaBande) {
              // si le graphique comporte à la fois un pourcentage et une unité particulière, comme " hab." pour le graphique 11, voici le format attendu: "50 % (538 015 hab.)".
              circleBullet.tooltipText = `{categoryX}: [bold]{pourcentageLabel} ({bulletText} ${this.graphInfo.contenuUnitesDansLaBande})[/]`;
            } else {
              // si le contenu de la chaîne d'unités est vide, mais "truthy" (= est défini) et qu'il y a bien présence d'un pourcentage, c'est qu'il faut afficher le nombre (sans unités) et le pourcentage dans l'infobulle
              circleBullet.tooltipText = '{categoryX}: [bold]{pourcentageLabel} ({bulletText})[/]';
            }
          } else {
            if (this.graphInfo.contenuUnitesDansLaBande) {
              circleBullet.tooltipText = `{categoryX}: [bold]{bulletText} ${this.graphInfo.contenuUnitesDansLaBande}[/]`;
            } else {
              circleBullet.tooltipText = '{categoryX}: [bold]{bulletText}[/]';
            }
          }

          circleBullet.circle.radius = 10;
          const valueLabel = series.bullets.push(new am4charts.LabelBullet());
          // valueLabel.label.text = '{valueY}';

          const showSecondary = this.graphInfo.secondaryInGraph === undefined || this.graphInfo.secondaryInGraph;
          if (showPourcentage) {
            // if (this.graphInfo.contenuUnitesDansLaBande) {
            //   valueLabel.label.text = `{totalFormatte} ${this.graphInfo.contenuUnitesDansLaBande}`;
            // }
            valueLabel.label.text = '{pourcentageLabel}'
            if (showSecondary) {
              if (showDollarSign) {
                valueLabel.label.text += ' {bulletText}';
              }
            }
          } else if (showDollarSign) {
            valueLabel.label.text = '{bulletText}';
          } else {
            valueLabel.label.text = '{totalFormatte}';
          }

          valueLabel.label.horizontalCenter = 'middle';
          valueLabel.label.dy = -20;
          valueLabel.label.hideOversized = false;
          valueLabel.label.truncate = false;
          if (this.graphInfo.boldLabels) {
            valueLabel.label.fontWeight = 'bold';
          }
        }
      }
    }
  },
  mounted () {
    this.chartData = this.data.data;
    this.errors = this.data.errors;
    this.generateGraphs();
  },
  created () {
    am4core.useTheme(am4themesMyTheme);
    // am4core.useTheme(am4themesKelly);
  }
})
</script>

<style scoped lang="scss">
  .graph {
    margin: 0 auto;
    align-items: center;
    width: 100%;
  }
  .graphique_et_tableau .graph {
    width: 85%;
  }
  .graph > div {
    height: 100%
  }
  .flex > .graph {
    flex-basis: 50%
  }
  .source-line:last-of-type {
    padding-bottom: 10px
  }

</style>
