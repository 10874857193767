<template>
  <div class="blue">

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Footer'
})
</script>

<style scoped lang="scss">
    .blue {
        height: 100px;
        background: blue;
    }
</style>
