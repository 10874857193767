import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-694ccb79"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "graphique-source-layout"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 1,
  class: "graphique-source-layout graphique-tarte-source"
}
const _hoisted_4 = {
  key: 2,
  class: "graphique-source-layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.graphInfoProp.sources)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (source, index) => {
            return (_openBlock(), _createElementBlock("a", {
              target: "_blank",
              href: source.lien,
              key: source
            }, _toDisplayString(source.reference) + " " + _toDisplayString(index == _ctx.sources.length -1 ? source.annee : source.annee + ','), 9, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.graphInfoProp.questionnaireSources)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.questionnaireSources), 1))
      : _createCommentVNode("", true),
    (_ctx.graphInfoProp.questionnaireTableauSources)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.questionnaireTableauSources), 1))
      : _createCommentVNode("", true)
  ], 64))
}