<template>
  <div class="filtre">
    <span class="titreFiltre">Rechercher par nom</span>
    <hr/>
    <div class="inputFiltre">
      <Dropdown ref="dropdown" class="inputFiltre-filtre" v-model="municipaliteSelectionnee" :options="dropdownDataMunicipalite" optionLabel="displayName" dataKey="_id" :autoFilterFocus="true" :resetFilterOnHide="true" emptyFilterMessage="Aucun résultat ne correspond à votre requête." placeholder="Région, MRC, Municipalité" :filter="true">
      <template #option="slotProps">
        <div style="display:flex; justify-content: space-between; flex-direction: row;">
          <div style="white-space:break-spaces; width:60%;">
            {{slotProps.option.displayName}}
          </div>
        </div>
      </template>
      </Dropdown>
      <button @click="ajouterMunicipalite" class="bouton">Ajouter <span class="icon">
        <i class="pi pi-plus"></i>
      </span></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
export default defineComponent({
  name: 'RechercheEndroitTexteUneBoite',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataMunicipalite: new Array<any>()
    }
  },
  watch: {
    '$route' (to, from) {
      this.setDropdownData();
    }
  },
  methods: {
    setDropDownDataMunicipalite () {
      this.dropdownDataMunicipalite = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr)
      })).map((item) => {
        if (item.typeEndroit === 'mrc') {
          item.typeEndroit = 'MRC';
        }

        if (item.typeEndroit === 'region') {
          item.typeEndroit = 'Région';
        }

        item.displayName = item.NOM_GEO + ' (' + item.typeEndroit + ')';
        if (item.typeEndroit === 'municipalite') {
          item.displayName = item.NOM_GEO + ' (municipalité)';
        }
        if (item.typeEndroit === 'municipalite' && item.NOM_MRC !== undefined) {
          item.displayName += ' - ' + item.NOM_MRC + ' (MRC)';
        }
        return item;
      });
      this.municipaliteSelectionnee = null;
    },
    async ajouterMunicipalite () {
      this.$loading.startLoading();
      const drop = this.$refs.dropdown as any;
      drop.filterValue = null;
      const endroitSelectionnee = this.municipaliteSelectionnee;
      if (endroitSelectionnee !== null) {
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData () {
      this.setDropDownDataMunicipalite();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    }
  },
  mounted () {
    this.setDropdownData();
  }
})

</script>

<style lang="scss" scoped>
  .titreFiltre {
    font-weight: 600;
    text-transform: uppercase;
  }

  .filtre {
    text-align:left;
  }

  /* Règle width: 50% à l'origine. Modifié par du flex. Cette règle ne semblait apporter aucun changement visuel. */
  .inputFiltre {
    display: flex;
    flex-wrap: wrap;
    &-filtre {
      width: 25%;
      margin-right: 2%;
    }
  }

  .bouton {
    border: none;
    border-radius: 4px;
    background: #5C72AF;
    color: white;
    font-weight: 700;
    // Pour que le bouton conserve sa hauteur, même en mode mobile, quand il est dessous la barre de recherche
    height: 37.25px;
    width: 10%;
    position: relative;
    // Pour uniformiser la transition sur les boutons. Il y en avait une sur celui-ci, mais pas sur le reste.
    transition: none;

    &:hover {
      background: #2A4DA1;
      cursor: pointer;
    }
  }
</style>
