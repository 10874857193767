<template>
  <div class="colonne-soit-graphique-soit-tableau">
    <!--
      Les attentes de la cliente par rapport au menu d'exportation sont décrites dans les billet 638 et 643. Comme ces attentes changent souvent, les différentes versions se trouvent ci-dessous et doivent être conservées en prévision d'un changement éventuel.
    -->

    <!-- boutons sans icône, avec libellé "Export PNG", "Export SVG", etc. -->

    <!-- <div class="exporter-bouton-container">
      <button @click="exporterPNG(idCanvas)" class="bouton-exportation">
        <div>Export</div>
        <div>PNG</div>
      </button>
      <button @click="exporterSVG(idCanvas)" class="bouton-exportation">
        <div>Export</div>
        <div>SVG</div>
      </button>
      <button @click="exporterExcel(graphInfo, data)" class="bouton-exportation">
        <div>Export</div>
        <div>Excel</div>
      </button>
    </div> -->

    <!-- menu exportation -->
    <div class="conteneur-menu-largeur-écran fullscreenJustifyEnd"> <!-- largeur de l'écran -->
      <div class="exporter-bouton-container"> <!-- 300 pixels de largeur -->
        <button @click="exporterPNG(idCanvas)" class="bouton-exportation bouton-exportation-avec-icone">
          PNG <i class="icone-exportation-fichier"></i>
        </button>
        <button @click="exporterSVG(idCanvas)" class="bouton-exportation bouton-exportation-avec-icone">
          SVG <i class="icone-exportation-fichier"></i>
        </button>
        <button @click="exporterExcel(graphInfo, data)" class="bouton-exportation bouton-exportation-avec-icone bouton-tableur">
          Excel <i class="icone-exportation-fichier"></i>
        </button>
        <button v-if="graphInfo.informationsText" @click="visible = true" class="bouton-informations">
          i
        </button>
      </div>
    </div>

    <!-- Il est nécessaire ici de viser un parent de la balise <slot>, et non la balise directement ou la référence aura pour valeur "undefined" -->
    <div class="exportingContent" ref="contenuAExporter">
      <!-- graphique, tableau ou les deux, selon la situation -->
      <slot />
    </div>
    <Dialog v-model:visible="visible" modal  :style="{ width: '50vw' }">
      <p v-html="graphInfo.informationsText"></p>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import domToImage from 'dom-to-image-more';
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { DescriptionGraphiqueDTO } from '../DTO/DescriptionGraphiqueDTO';
import { TableauLogicHelper } from '@/helpers/TableauLogicHelper';
import { Column, Workbook, Worksheet } from 'exceljs';
import { TableauLigneDTO } from '@/DTO/Tableau/TableauLigneDTO';

export default defineComponent({
  name: 'Exports',
  emits: ['startExport', 'endExport'],
  props: {
    idCanvas: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    graphInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      nameNotToFormat: [
        '5- Indiquez les types de programmation généralement offerts en COLLABORATION',
        '6- Indiquez les types de programmation généralement DÉLÉGUÉS par votre municipalité',
        '7- Indiquez les types de programmation généralement OFFERTS par votre municipalité',
        '0-5 ans (Petite enfance)',
        '6-12 ans (Enfance)',
        '13-17 ans (Adolescence)',
        '18-30 ans (Jeunes adultes)',
        '31-64 ans (Adultes)',
        '65-84 ans (Retraités)',
        '85 ans et plus (Ainés)',
        '11- Indiquez les catégories d\'âges habituellement visées par les activités déléguées à d\'autres organisations',
        '12- Indiquez les catégories d\'âges habituellement visées par les activités réalisées en collaboration',
        '13- Indiquez les catégories d\'âges habituellement visées par vos activités',
        '14- Selon vous, quelle est la catégorie de clientèle qui est la moins bien desservie que les autres dans l\'offre municipale?',
        '15- Cochez l\'affirmation qui correspond généralement à votre réalité',
        '16- Cochez l\'affirmation qui correspond généralement à votre réalité',
        '17- Généralement, d\'où proviennent les participants à vos activités de loisir (avec inscription)?',
        '18- Comment procédez-vous généralement avec les citoyens résidents à l\'extérieur de votre municipalité pour les activités avec inscription?',
        '22- Combien avez-vous d\'organisme(s) incorporé(s), ayant leur siège social sur votre territoire? Le chiffre peut être approximatif',
        '23- Combien sont soutenus par la municipalité?',
        '1ere place',
        '2e place',
        '3e place'
      ]
    }
  },
  methods: {
    getTrueData (graphInfo: DescriptionGraphiqueDTO, data: any) {
      const tableauLogique = new TableauLogicHelper(graphInfo, data)
      tableauLogique.init();
      const colonnesArray = Array.of(...tableauLogique.Colonnes).map(colName => {
        return { field: colName, header: colName }
      });
      return { trueData: tableauLogique.TrueData, colonnesArray, tableauLogique };
    },
    getDataInOrder (data: Array<any>) {
      if (!data.some(dataItem => dataItem.colonnes.length !== 1)) {
        data.sort((a, b) => {
          return parseFloat(b.colonnes[0].value) - parseFloat(a.colonnes[0].value);
        })
      }
      return data
    },
    getTopCol (tableauLogique: TableauLogicHelper, graphInfo: DescriptionGraphiqueDTO) {
      let topCol = tableauLogique.ColonnesTop;
      if (graphInfo.tableauGroupeInfo) {
        topCol = [graphInfo.tableauGroupeInfo.nomPourcentage, graphInfo.tableauGroupeInfo.nomTotal];
      }
      return topCol;
    },
    addTitre (sheet: Worksheet, graphInfo: DescriptionGraphiqueDTO, colLength: number) {
      sheet.addRow([graphInfo.name]);
      sheet.mergeCells('A1:' + String.fromCharCode(64 + colLength) + '1');
      sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    },
    addTopCols (sheet: Worksheet, graphInfo: DescriptionGraphiqueDTO, topCol: Array<string>, colLength: number) {
      const topHeaderLigneIndex = 2;
      const merges = new Array<string>();
      const startLetters = new Array<string>();
      if (topCol.length > 0) {
        const topRow = new Array<any>();
        if (!graphInfo.tableauHideFirstColumn) {
          topRow.push('');
        }
        const colspan = (colLength - 1) / topCol.length;
        topCol.forEach((colName, index) => {
          topRow.push(colName)
          for (let x = 1; x < colspan; x++) {
            topRow.push('')
          }
          const startIndex = index * colspan + 2;
          const endIndex = startIndex + colspan - 1;
          const startLetter = String.fromCharCode(64 + startIndex);
          const endLetter = String.fromCharCode(64 + endIndex);
          startLetters.push(startLetter);
          merges.push(startLetter + topHeaderLigneIndex + ':' + endLetter + topHeaderLigneIndex);
        })
        sheet.addRow(topRow);
        startLetters.forEach(letter => {
          const cell = sheet.getCell(letter + topHeaderLigneIndex);
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9D9D9' }
          }
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        })
        merges.forEach(element => {
          sheet.mergeCells(element);
        });
      }
    },
    addCols (sheet: Worksheet, topCol: Array<string>, columns: Array<{ title: string, field: string } | { header: string, field:string }>) {
      const headerRowIndex = topCol.length > 0 ? '3' : '2';
      sheet.addRow(columns.map(col => {
        if ('title' in col) {
          return this.removeHeaderPrefix(col.title);
        } else {
          return this.removeHeaderPrefix(col.header);
        }
      }));
      for (let x = 1; x <= columns.length; x++) {
        sheet.getCell(String.fromCharCode(64 + x) + headerRowIndex).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9D9D9' }
        }
      }
    },
    addData (data: Array<TableauLigneDTO>, sheet: Worksheet, columns: Array<{ title: string, field: string } | { header: string, field:string }>) {
      data.forEach((dataItem) => {
        const rowObject = new Array<any>();
        columns.forEach(col => {
          let rowValue = dataItem[col.field];
          if (rowValue === undefined || rowValue === null || rowValue === '') {
            rowValue = '';
          } else if (!isNaN(rowValue)) {
            if (typeof rowValue === 'boolean') {
                // variable is a boolean
                rowValue = rowValue ? 'Oui' : 'Non';
            } else {
              rowValue = parseFloat(rowValue);
            }
          } else if (!this.nameNotToFormat.includes(rowValue)) {
            var correspondance = rowValue.match(/\d{1,3}(?: \d{3})*(?:\.\d+)?/);
            if (correspondance != null) {
              var premierNombre = correspondance ? parseFloat(correspondance[0].replace(/ /g, '').replace(',', '.')) : null;
              rowValue = premierNombre;
            }
          }
          rowObject.push(rowValue);
        })
        const row = sheet.addRow(rowObject);
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        })
      })
    },
    setBordersToCells (sheet: Worksheet) {
      for (let c = 1; c <= sheet.columns.length; c++) {
        for (let r = 1; r <= sheet.rowCount; r += 1) {
          const cell = sheet.getCell(r, c);
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        }
      }
    },
    setColsWidth (sheet: Worksheet) {
      for (let i = 0; i < sheet.columns.length; i++) {
        let dataMax = 0;
        const column = sheet.columns[i] as Column;
        for (let j = 2; j < column.values.length; j += 1) {
          const colValue = column.values[j];
          if (colValue !== undefined && colValue !== null) {
            const columnLength = typeof colValue === 'string' ? colValue.length : 10;
            if (columnLength > dataMax) {
              dataMax = columnLength;
            }
          }
        }
        column.width = dataMax < 10 ? 10 : dataMax;
      }
    },
    downloadExcel (workbook: Workbook) {
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = 'export.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    exporterExcel (graphInfo: DescriptionGraphiqueDTO, data: any) {
      const { trueData, colonnesArray, tableauLogique } = this.getTrueData(graphInfo, data)
      const columns = graphInfo.tableauHideFirstColumn ? colonnesArray : [{ field: 'valeurPremiereColonne', title: tableauLogique.titrePremiereLigneTableau() }, ...colonnesArray];
      const topCol = this.getTopCol(tableauLogique, graphInfo);
      const workbook = new Workbook();
      const sheet = workbook.addWorksheet('Donnees');

      this.addTitre(sheet, graphInfo, columns.length); // ajoute le titre du graphique en haut du tableau
      this.addTopCols(sheet, graphInfo, topCol, columns.length); // ajoute les noms des catégories de colonnes en haut du tableau
      this.addCols(sheet, topCol, columns); // ajoute les noms des colonnes en haut du tableau
      this.addData(trueData, sheet, columns); // ajoute les données du tableau
      this.setBordersToCells(sheet); // ajoute les bordures aux cellules
      this.setColsWidth(sheet); // ajuste la largeur des colonnes
      this.downloadExcel(workbook); // télécharge le fichier excel
    },
    removeHeaderPrefix (str: string): string {
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        const prefixPos = str.indexOf('_');
        if (prefixPos !== -1) {
          return str.substring(0, prefixPos);
        }
        return str;
      }
      return this.removePrefix(this.removePrefix(str, 'total-'), 'pourcentage-');
    },
    removePrefix (str: string, prefix: string): string {
      if (str.startsWith(prefix)) {
        return str.slice(prefix.length);
      }
      return str;
    },
    /**
     * Premier paramètre: élément HTML.
     * Deuxième paramètre: "block" ou "none" (valeur de "display").
     */
    afficheCacheSourceTableau (elementHTML: HTMLElement, nouvelEtat: string) {
      if (elementHTML !== null) {
        elementHTML.style.display = nouvelEtat;
      }
    },
    exporterPNG () {
      // pour afficher la source au moment de l'exportation
      this.$emit('startExport');
      const elements = document.querySelectorAll<HTMLElement>('.p-datatable-responsive-scroll > .p-datatable-wrapper');
      for (var i = 0; i < elements?.length; i++) {
        const element = elements[i];
        element.style.overflow = 'hidden'
      }
      const node = this.$refs.contenuAExporter as HTMLElement;
      // const node = this.$refs[reference] as Node;
      // const node = this.$refs.exportTable as Node;

      const scale = 5;
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: node.clientWidth + 'px', // use original width of DOM element to avoid part of the image being cropped out
        height: node.clientHeight + 'px' // use original height of DOM element
      };
      domToImage.toPng(node, {
        width: node.clientWidth * scale,
        height: node.clientHeight * scale,
        style: style
      }).then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'export.png';
        link.href = dataUrl;
        link.click();

        // une fois l'exportation faite, on cache à nouveau le tableau
        for (var i = 0; i < elements?.length; i++) {
          const element = elements[i];
          element.style.overflow = 'auto'
        }
        this.$emit('endExport');
      }).catch(function (err) {
        console.error('oops, something went wrong!', err);
      });
    },

    filter (node: HTMLElement) {
      return (node.tagName !== 'i');
    },

    async sleep (milliseconds: any) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    },

    async exporterSVG () {
      // pour afficher la source au moment de l'exportation
      this.$emit('startExport');
      await this.sleep(10);

      const node = this.$refs.contenuAExporter as HTMLElement;

      const iconeTriTableau: any = document.getElementsByClassName('p-sortable-column-icon');
      for (var i = 0; i < iconeTriTableau.length; i++) {
        iconeTriTableau[i].style.visibility = 'hidden';
      }
      const svgDocument = elementToSVG(node);
      // Inline external resources (fonts, images, etc) as data: URIs
      await inlineResources(svgDocument.documentElement)
      // Get SVG string
      var source = new XMLSerializer().serializeToString(svgDocument)

      /*eslint-disable */
      if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }

      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }

      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

      /* eslint-enable */
      const link = document.createElement('a');
      link.download = 'export.svg';
      link.href = url;
      link.click();
      for (var j = 0; j < iconeTriTableau.length; j++) {
        iconeTriTableau[j].style.visibility = 'visible';
      }

      // pour afficher la source au moment de l'exportation
      this.$emit('endExport');
    }
  }
})
</script>

<style scoped lang="scss">
  button {
    height: 35px;
    margin: 0 10px 0 10px;
    width: 85px;
  }
  .fullscreenJustifyEnd {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
    right: 0px;
  }
  .exportingContent {
    width: 100%;
    position: relative;
  }
  // Il est important de conserver la classe CSS ci-dessous, comme la cliente a changé d'idée quelques fois à ce sujet. Voir le billet 638 dans Azure pour avoir plus d'infos là-dessus.
  .bouton-exportation {
    background-color: white;
    border: 2px solid #5C72AF;
    border-radius: 5px;
    box-shadow: none;
    color: #2A4DA1;
    // Pour empêcher que la mauvaise police se charge dans les boutons d'exportation, probablement à cause d'un composant PrimeVue.
    font-family: 'Encode Sans', Avenir, Helvetica, Arial, sans-serif;
    font-weight: 500;
    height: 40px;
    width: 85px;
    transition-duration: 0.5s;

    &:hover{
      border-color: #5C72AF;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .bouton-informations {
    background-color: #19324a;
    color:white;
    height: 20px;
    width: 20px;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
  }
  .bouton-informations:hover {
    background-color: #001b33;
    color:white;
  }

  .bouton-exportation-avec-icone {
    // Partie flex du CSS du bouton d'exportation.
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    // Affichage du texte dans une moitié du bouton et de l'icône dans l'autre.
    & div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }

  // pour styliser les menus sur toutes les pages, sauf Graphique et tableau
  .colonne-soit-graphique-soit-tableau {
    position: relative;
  }
  .colonne-soit-graphique-soit-tableau .conteneur-menu-largeur-écran {
    display: flex;
    justify-content: flex-end;
  }

  // pour cacher les boutons d'exportation du tableur dans l'onglet Graphique et tableau
  .graphique_et_tableau .bouton-tableur {
    display: none;
  }

  // Contrairement aux icônes du composant Sidebar.vue, cette icône
  .icone-exportation-fichier {
    background-image: url('../assets/icons/icone-exportation-fichier.svg');
    height: 16px;
    width: 18px;
  }

  .exporter-bouton-container {
    text-align: end;
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  /*MEDIA QUERIES*/
  // Pour éviter que le menu d'exportation chevauche les onglets "Graphique", "Tableau simple", etc., lorsque l'écran d'ordinateur est peu large/en mode mobile.
  @media screen and (min-width: 1225px) {
    // règles pour tous les onglets, sauf le dernier
    .fullscreenJustifyEnd {
      position: absolute;
    }
    .conteneur-menu-largeur-écran .exporter-bouton-container:first-of-type {
      padding-right: 5%;
    }
    .colonne-soit-graphique-soit-tableau .conteneur-menu-largeur-écran .exporter-bouton-container {
      top: -70px;
      position: absolute;
      padding-right: 0px;
      z-index: 9999;
    }
  }
</style>
