<template>
  <div class="filtre">
    <span class="titreFiltre">Rechercher par Région/MRC</span>
    <hr/>
    <div class="inputFiltre">
      <Dropdown ref="dropdown" class="inputFiltre-filtre" v-model="regionSelectionnee" :options="dropdownDataRegion" optionLabel="NOM_GEO" dataKey="_id" emptyFilterMessage="Aucun résultat ne correspond à votre requête." placeholder="Région" :filter="true" @change="setDropDownDataMRC(true)" :autoFilterFocus="true" :resetFilterOnHide="true" >
      <template #option="slotProps">
        <div style="display:flex; justify-content: space-between; flex-direction: row;">
          <div style="white-space:break-spaces; width:60%;">
            {{slotProps.option.NOM_GEO}}
          </div>
        </div>
      </template>
      </Dropdown>
      <Dropdown ref="dropdown" class="inputFiltre-filtre"  v-model="mrcSelectionnee" :options="dropdownDataMRC" optionLabel="NOM_GEO" dataKey="_id" emptyFilterMessage="Aucun résultat ne correspond à votre requête." placeholder="MRC" :filter="true" @change="setDropDownDataMunicipalite" :autoFilterFocus="true" :resetFilterOnHide="true" >
      <template #option="slotProps">
        <div style="display:flex; justify-content: space-between; flex-direction: row;">
          <div style="white-space:break-spaces; width:60%;">
            {{slotProps.option.NOM_GEO}}
          </div>
        </div>
      </template>
      </Dropdown>
      <Dropdown ref="dropdown" class="inputFiltre-filtre" v-if="mrcSelectionnee !== null" v-model="municipaliteSelectionnee" :options="dropdownDataMunicipalite" optionLabel="NOM_GEO" dataKey="_id" emptyFilterMessage="Aucun résultat ne correspond à votre requête." placeholder="Municipalité" :filter="true" :autoFilterFocus="true" :resetFilterOnHide="true" >
      <template #option="slotProps">
        <div style="display:flex; justify-content: space-between; flex-direction: row;">
          <div style="white-space:break-spaces; width:60%;">
            {{slotProps.option.NOM_GEO}}
          </div>
        </div>
      </template>
      </Dropdown>
      <button @click="ajouterMunicipalite" class="bouton">Ajouter <span class="icon">
        <i class="pi pi-plus"></i>
      </span></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from 'vue'
import Dropdown from 'primevue/dropdown';
import { EndroitDTO } from '../DTO/EndroitDTO'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { EndroitType } from '@/DTO/EndroitType';
export default defineComponent({
  name: 'RechercheEndroitTexte',
  props: {
    endroitsData: {
      type: Array as PropType<EndroitDTO[]>,
      required: true
    }
  },
  components: {
    Dropdown
  },
  setup () {
    const endroits = inject('endroits') as Ref<Array<EndroitDTO>>
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroits,
      endroitSelectionHelper
    }
  },
  data () {
    return {
      regionSelectionnee: null as EndroitDTO | null,
      mrcSelectionnee: null as EndroitDTO | null,
      municipaliteSelectionnee: null as EndroitDTO | null,
      dropdownDataRegion: new Array<EndroitDTO>(),
      dropdownDataMRC: new Array<EndroitDTO>(),
      dropdownDataMunicipalite: new Array<EndroitDTO>()
    }
  },
  watch: {
    '$route' (to, from) {
      this.setDropdownData();
    }
  },
  methods: {
    setDropDownDataRegion () {
      this.dropdownDataRegion = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr) && (endr.typeEndroit === EndroitType.Region)
      }));
      this.regionSelectionnee = null;
      this.mrcSelectionnee = null;
      this.municipaliteSelectionnee = null;
    },
    setDropDownDataMRC (hasRegionSelected:boolean) {
      if (hasRegionSelected) {
        this.dropdownDataMRC = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
          return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.MRC && endr.idParent === this.regionSelectionnee?._id
        }));
      } else {
        this.dropdownDataMRC = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
          return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.MRC
        }))
      }
      this.mrcSelectionnee = null;
      this.municipaliteSelectionnee = null;
    },
    setDropDownDataMunicipalite () {
      this.dropdownDataMunicipalite = this.getSortedEndroitArray(this.endroitsData.filter(endr => {
        return !this.endroits.includes(endr) && endr.typeEndroit === EndroitType.municipalite && endr.idParent === this.mrcSelectionnee?._id
      }));
      this.municipaliteSelectionnee = null;
    },
    async ajouterMunicipalite () {
      this.$loading.startLoading();
      const drop = this.$refs.dropdown as any;
      drop.filterValue = null;
      let endroitSelectionnee: EndroitDTO | null = null;
      if (this.municipaliteSelectionnee != null) {
        endroitSelectionnee = this.municipaliteSelectionnee;
      } else if (this.mrcSelectionnee != null) {
        endroitSelectionnee = this.mrcSelectionnee;
      } else if (this.regionSelectionnee != null) {
        endroitSelectionnee = this.regionSelectionnee;
      }
      if (endroitSelectionnee !== null) {
        this.endroitSelectionHelper.addEndroitAndHandleType(endroitSelectionnee, this.$toast, this.$store).then(() => {
          this.$emit('onEndroitUpdated');
        });
      }
    },
    setDropdownData () {
      this.setDropDownDataRegion();
      this.setDropDownDataMRC(false);
      this.setDropDownDataMunicipalite();
    },
    getSortedEndroitArray (endroits: EndroitDTO[]) {
      return endroits.sort((a, b) => {
        if (a.NOM_GEO < b.NOM_GEO) {
          return -1
        } else if (a.NOM_GEO > b.NOM_GEO) {
          return 1
        } else {
          return 0
        }
      });
    }
  },
  mounted () {
    this.setDropdownData();
    this.$loading.stopLoading()
  }
})

</script>

<style lang="scss" scoped>
  .titreFiltre {
    font-weight: 600;
    text-transform: uppercase;
  }

  .filtre {
    text-align:left;
  }

  /* Règle width: 50% à l'origine. Modifié par du flex. Cette règle ne semblait apporter aucun changement visuel. */
  .inputFiltre {
    display: flex;
    flex-wrap: wrap;
    &-filtre {
      width: 25%;
      margin-right: 2%;
    }
  }

  .bouton {
    border: none;
    border-radius: 4px;
    background: #5C72AF;
    color: white;
    font-weight: 700;
    // Pour que le bouton conserve sa hauteur, même en mode mobile, quand il est dessous la barre de recherche
    height: 37.25px;
    width: 10%;
    position: relative;
    // Pour uniformiser la transition sur les boutons. Il y en avait une sur celui-ci, mais pas sur le reste.
    transition: none;

    &:hover {
      background: #2A4DA1;
      cursor: pointer;
    }
  }
</style>
