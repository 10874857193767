<template>
    <div class="panelItemContainer" >
      <RechercheEndroitTexteUneBoite @onEndroitUpdated="onEndroitUpdated" v-if="allEndroitsData.length > 0" :endroitsData="allEndroitsData"/>
    </div>
    <div class="panelItemContainer" >
      <RechercheEndroitTexte @onEndroitUpdated="onEndroitUpdated" v-if="allEndroitsData.length > 0" :endroitsData="allEndroitsData"/>
    </div>
    <div class="panelItemContainer">
      <RechercheEndroitParRepondant @onEndroitUpdated="onEndroitUpdated" v-if="allEndroitsData.length > 0" :endroitsData="allEndroitsData"/>
    </div>
    <div class="panelItemContainer">
      <RechercheEndroitCarte @onEndroitUpdated="onEndroitUpdated" ref="RechercheEndroitCarte" :endroitsData="allEndroitsData" />
    </div>
    <div class="panelItemContainer">
      <RechercheEndroitCriteres @onEndroitUpdated="onEndroitUpdated" :endroitsData="allEndroitsData" />
    </div>
</template>

<script lang="ts">
import { EndroitDTO } from '@/DTO/EndroitDTO'
import { defineComponent, PropType } from 'vue'
import RechercheEndroitCarte from '../components/RechercheEndroitCarte.vue'
import RechercheEndroitTexte from '@/components/RechercheEndroitTexte.vue'
import RechercheEndroitCriteres from '@/components/RechercheEndroitCriteres.vue'
import RechercheEndroitTexteUneBoite from '@/components/RechercheEndroitTexteUneBoite.vue'
import RechercheEndroitParRepondant from '@/components/RechercheEndroitParRepondant.vue'
export default defineComponent({
  name: 'Recherche',
  props: {
    allEndroitsData: {
      type: Array,
      required: true
    }
  },
  components: {
    RechercheEndroitCarte,
    RechercheEndroitTexte,
    RechercheEndroitCriteres,
    RechercheEndroitTexteUneBoite,
    RechercheEndroitParRepondant
  },
  methods: {
    onEndroitUpdated () {
      (this.$refs.RechercheEndroitCarte as any).updateEndroitSelected();
    }
  },
  mounted () {
    this.$loading.stopLoading();
  }
})
</script>
<style lang="scss" scoped>
  .panelItemContainer > div {
    width: 100%;
    padding-bottom: 15px;
  }

  ::v-deep(.panelItemContainer) {
    height: fit-content;
    width: 95%;
    margin: 1vh 2.5vw;
    padding: 2.5%;
    border-radius: 10px;
    background-color: white;
  }
</style>
