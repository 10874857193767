<template>
  <div class="error-box" v-if="errorMessage.length > 0">
    {{ errorMessage }}
  </div>

  <!-- libellés des questions en mode grand écran; cachés en mode mobile -->
  <div :class="`${ questionProp.classeCssGrille ? questionProp.classeCssGrille : '' } rangee-sous-question cache-en-mobile`">
    <span class="cache-en-mobile"></span>
    <span
      class="cache-en-mobile element-grille-reponses-a-centrer"
      v-for="libelle of reponsesPossibles"
      :key="libelle"
    >
      {{ libelle }}
    </span>
  </div>

  <!-- boucle pour générer les sous-questions et les boutons associés -->
  <!-- classe pour appliquer la classe de la grille au besoin -->
  <div v-for="sousQuestion of questionProp.sousQuestions" :key="sousQuestion">
    <div
      :class="`${ questionProp.classeCssGrille ? questionProp.classeCssGrille : '' } rangee-sous-question`"
    >
      <span>{{ sousQuestion.question }}</span>

      <span
        class="element-grille-reponses-a-centrer"
        :key="reponse"
        v-for="reponse of reponsesPossibles"
      >
        <RadioButton
          @change="onValueChange(sousQuestion)"
          :name="sousQuestion.question"
          :value="reponse"
          v-model="value[sousQuestion.question]"
        />

        <span class="cache-en-desktop">{{ reponse }}</span>
      </span>

    </div>

  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import RadioButton from 'primevue/radiobutton';

export default defineComponent({
  name: 'InputGrilleRadioMaison',
  components: {
    RadioButton
  },
  data () {
    return {
      errorMessage: '',
      value: {} as any,
      reponsesPossibles: new Set<string>()
    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  created () {
    type repType = { reponsesPossibles: { texteReponse: string }[] };
    for (const sousQuestion of Object.values(this.questionProp.sousQuestions) as repType[]) {
      sousQuestion.reponsesPossibles.forEach(reponse => {
        this.reponsesPossibles.add(reponse.texteReponse)
      })
    }
  },
  methods: {
    onValueChange (sousQuestion: { question: string, reponseChoisie: any }) {
      sousQuestion.reponseChoisie = this.value[sousQuestion.question];
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // conversion de l'objet this.value en array pour ensuite en récupérer la longueur
      const arrReponsesGrille = Object.keys(this.value);
      const nbReponsesFournies = arrReponsesGrille.length;

      // si la longueur de l'array this.value est plus petite que celle de this.questionProp.sousQuestions.length, c'est qu'on n'a pas répondu à certaines questions.
      if (nbReponsesFournies < this.questionProp.sousQuestions.length) {
        this.errorMessage = 'Une ou plusieurs réponses manquantes.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
</script>

<style lang="scss" scoped>
  // pour que le libellé ne soit pas directement collé sur le bouton radio ou la case à cocher
  label {
    margin-left: 5px;
  }

  .margin-entre-reponses-questionnaire {
    // pour que le texte de la question, ne se retrouve pas sous le bouton radio/la case à cocher
    display: grid;
    grid-template-columns: auto 1fr;

    // pour que les questions soit légèrement décollées entre elles
    margin: 10px;
  }

  // media queries
  @media screen and (max-width: 880px) {
    .cache-en-mobile {
      display: none;
    }

    // en mobile pour que les cases à cocher soient alignées avec celles des autres sortes de questions
    .element-grille-reponses-a-centrer {
      margin: 0 10px 0 10px;
    }

    .grille-4-reponses {
      display: flex;
      flex-direction: column;
    }

    .grille-5-reponses {
      display: flex;
      flex-direction: column;
    }

    // en mobile, les grilles de questions s'affichent en rangée
    .rangee-sous-question {
      display: flex !important;
      flex-direction: column;
      margin-top: 30px !important;

      // pour que les boutons radio ne sont pas collés sur le texte situé à leur droite
      .cache-en-desktop {
        margin-left: 5px;
      }

      // en mobile, les éléments ne sont plus à centrer
      .element-grille-reponses-a-centrer {
        margin-top: 10px;
      }
    }

    // plus d'espace autour de chaque question
    .sous-conteneur-blanc {
      padding: 25px 10px 25px !important;
    }

    // le libellé ne s'affiche pas sous le bouton radio, mais un peu plus à droite
    .margin-entre-reponses-questionnaire {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: left;
    }
  }
  @media screen and (min-width: 881px) {
    // pour cacher certains titres dans des choix de réponse dans une grille de questions, en mode desktop
    .cache-en-desktop {
      display: none;
    }

    .element-grille-reponses-a-centrer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .espace-en-desktop > span {
      background: lightgreen !important;
      text-decoration: underline;
      width: 100px;
      display: inline-block;
    }

    .grille-4-reponses {
      display: grid;
      grid-template-columns: 400px 1fr 1fr 1fr 1fr;
      margin: 10px;
    }

    .grille-5-reponses {
      display: grid;
      grid-template-columns: 400px 1fr 1fr 1fr 1fr 1fr;
      margin: 10px;
    }
  }
</style>
