<template>
  <div class="customNumberResponse">
    <InputNumber
      :min="0"
      :max="9999"
      v-model="value"
      @input="onValueChange"
      buttonLayout="horizontal"
      decrementButtonClass="p-button-secondary"
      decrementButtonIcon="pi pi-minus"
      incrementButtonClass="p-button-secondary"
      incrementButtonIcon="pi pi-plus"
      showButtons
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
  name: 'InputNumberMaison',
  components: {
    InputNumber
  },
  data () {
    return {
      value: 0
    }
  },
  created () {
    this.value = this.modelValue
  },
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange (event: any) {
      this.$emit('update:modelValue', event.value);
    }
  }
})
</script>
<style>
.customNumberResponse .p-button {
  background: #324e9c !important;
  border-color: #324e9c !important;
}
</style>
