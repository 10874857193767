<template>
  <div class="error-box" v-if="errorMessage.length > 0">
    {{ errorMessage }}
  </div>

  <Dropdown
    @change="onValueChange"
    optionLabel="texteReponse"
    :optionValue="optionValue"
    :options="optionData"
    placeholder="Sélectionner..."
    v-model="value"
    class="dropdown"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Dropdown from 'primevue/dropdown';
import { FilterKeyValue } from '@/DTO/FilterKeyValue';
import { QuestionnaireDropdownChangeResponse } from '@/DTO/QuestionnaireDropdownChangeResponse';
import { QuestionnaireContenuSectionItem } from '@/DTO/QuestionnaireContenuSectionItem';
export default defineComponent({
  name: 'InputDropdownMaison',
  components: {
    Dropdown
  },
  computed: {
    useFullData (): boolean {
      return this.optionData.length > 0 && 'id' in this.optionData[0];
    },
    optionValue () {
      return this.useFullData ? undefined : 'texteReponse';
    }
  },
  data () {
    return {
      allOptionData: [] as Array<any>,
      optionData: [] as Array<any>,
      errorMessage: '',
      value: '' as any
    }
  },
  created () {
    this.value = this.modelValue
  },
  props: {
    modelValue: {
      type: [String, Object],
      required: true
    },
    questionProp: {
      type: Object as PropType<QuestionnaireContenuSectionItem>,
      required: true
    },
    questionId: {
      type: Number,
      required: false
    },
    order: {
      type: Boolean,
      required: false
    }
  },
  emits: ['update:modelValue', 'change'],
  watch: {
    questionProp (value) {
      this.optionData = value.reponsesPossibles;
      this.allOptionData = value.reponsesPossibles;
    }
  },
  mounted () {
    this.optionData = this.questionProp.reponsesPossibles;
    this.allOptionData = this.questionProp.reponsesPossibles;
    this.questionProp.setSelfInputConponent(this);
    if (this.order) {
      this.orderOption();
    }
  },
  methods: {
    onValueChange (event: any) {
      this.$emit('update:modelValue', event.value);
      this.questionProp.reponsesPossibles.forEach((element:any) => {
        if (element === this.questionProp.reponseChoisie || element.texteReponse === this.questionProp.reponseChoisie) {
          this.$emit('change', { responseId: element.id, questionId: this.questionId, dependsOn: this.questionProp.dependsOn } as QuestionnaireDropdownChangeResponse);
        }
      });
    },
    updateFilter () {
      if (this.questionProp.filterWith?.parentId) {
        if (this.allOptionData.length > 0) {
          if (this.allOptionData[0].idParent != null) {
            this.optionData = this.allOptionData.filter(x => x.idParent === this.questionProp.filterWith!.parentId)
          }
        }
      }
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    },
    orderOption () {
      if (this.questionId === 4) {
        this.optionData.sort(function (a: any, b: any) {
          if (parseInt(a.texteReponse) < parseInt(b.texteReponse)) {
            return -1;
          }
          if (parseInt(a.texteReponse) > parseInt(b.texteReponse)) {
            return 1;
          }
          return 0;
        });
      } else {
        this.optionData.sort(function (a: any, b: any) {
          if (a.texteReponse < b.texteReponse) {
            return -1;
          }
          if (a.texteReponse > b.texteReponse) {
            return 1;
          }
          return 0;
        });
      }
    }
  }
})
</script>
<style>
.grid-section-1 .dropdown {
  width: 80%;
}
</style>
