<template>
  <div class="flex-container">
      <div class="resetPasswordContainer">
        <div class="container">
          <div>
            <div>
              <img id="logo" src="/images/logo-RURLS.png" alt="">
            </div>
            <div>
              <h2>Réinitialiser le mot de passe</h2>
            </div>
            <div class="notice">
              <span>Entrez votre adresse courriel ci-dessous pour recevoir les instructions pour réinitialiser votre mot de passe par courriel</span>
            </div>
            <div class="error" v-if="errorMessage != ''">
              {{errorMessage}}
            </div>
            <div class="success" v-if="successMessage != ''">
              {{successMessage}}
            </div>
            <div>
              <input type="text" placeholder="Entrez l'email" name="email" v-model="v$.email.$model" required>
            </div>
            <div>
              <button class="boutonbleu" type="submit" @click="onSubmit">Réinitialiser</button>
            </div>
            <div>
              <a class="forgot-password" href="/">Retour à la page de connexion</a>
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <img src="../../public/images/fond-reseau.png"/>
      </div>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default defineComponent({
  name: 'Forgot-Password',
  setup () {
    const state = reactive({
      email: ''
    })
    const rules = {
      email: { required, email }
    }

    const v$ = useVuelidate(rules, state)

    return { v$, state }
  },
  data () {
    return {
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    validateForm () {
      this.errorMessage = '';
      const invalid: boolean = this.v$.email.$invalid;
      if (invalid === true) {
        const error = this.v$.email.$errors[0];
        if (error.$validator === 'required') {
          this.errorMessage = 'L\'adresse courriel est requise';
        } else if (error.$validator === 'email') {
          this.errorMessage = 'L\'adresse courriel est invalide';
        }
      }

      return !invalid;
    },
    onSubmit () {
      this.successMessage = '';
      this.v$.email.$touch();
      const credentials = { email: this.state.email }
      if (!this.validateForm()) return;
      this.$store.dispatch('auth/requestResetPassword', credentials).then(
        () => {
          this.successMessage = 'Un courriel de réinitialisation de mot de passe a été envoyé à votre adresse courriel';
        },
        () => {
          this.errorMessage = 'Une erreur est survenue lors de l\'envoi de votre requête';
        }
      );
    }
  }

})
</script>

<style scoped>
html {
  height:100%;
}

body {
  height:100%;
  width:100%;
}

#app{
  height:100%;
}

.flex-container{
  display:flex;
  flex-direction: row;
  background-color:white;
  height:100%;
}

.notice{
  color:#46ADE2;
  background-color: #E5F6FD;
  width:100%;
  font-weight: 400;
  font-size:14px;
  text-align:center;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

.error{
  color:red;
  background-color: #FFEDED;
  width:100%;
  font-weight: 400;
  font-size:14px;
  text-align:center;
  margin-top:15px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

.success {
  color:#003300;
  background-color: #ccffcc;
  width:100%;
  font-weight: 400;
  font-size:14px;
  text-align:center;
  margin-top:15px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

a {
  color: #5C72AF;
  font-size:14px;
  font-weight: 400;
  cursor: pointer;
}

.right-side {
  width: 65%;
  background-image: linear-gradient(to left,#001B33,#001B33C9,#38A295);
  opacity:1;
}

.right-side img {
  width: 88%;
  height: 99.63%;
  object-fit: cover;
  float:right;
}

.gradient{
  width:27%;
  background: transparent linear-gradient(0deg, var(--unnamed-color-ffffff) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

form {
    width: 100%;
    height:100%;
    background-color: white;
}

label{
  font-size:14px;
  color:#F0753B;
  font-weight: 500;
}
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: 400;
}

img#logo{
  width: 70%;
  margin: 0 auto ;
}

h1{
  font-size: 36px;
  letter-spacing: 0.15px;
  color: #F0753B;
  margin-top: 15%;
  margin-bottom: 15%;
  width:100%;
  font-weight:400;
}

h2{
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  font-weight:600;
  letter-spacing: 0.15px;
}

/* Set a style for all buttons */
button {
  background : #F0753B 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 4px;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  font-size:14px;
}

button:hover {
  opacity: 0.8;
}

input{
    border: 0 !important;
    outline: 0;
    border-bottom: 1px solid #4F5866 !important;
    text-align:center;
    margin-top:7.5%;
    margin-bottom:7.5%;
}
/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
  margin: 0 auto;
  height: 80%;
  width: 80%;
  display:flex;
  flex-direction: column;
  position: relative;
}

.container > div {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .right-side {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .resetPasswordContainer{
    width:80%!important;
      height: 80%;
      margin: 0 auto;
      position: relative;
      background: white;
      top: 10%;
  }

  h1{
    margin: 10px auto;
    width: auto;
  }

  .container{
    margin: 0 auto;
    position: relative;
    height: 80%;
    width: 80%;
    top: 5%;
    display:flex;
    flex-direction: column;
    justify-content:center;
  }

  .flex-container{
    opacity:1;
    background: transparent url(../../public/images/fond-reseau.png) 0% 0% no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center;
  }
}
</style>
