<template>
    <div class="error-box" v-if="errorMessage.length > 0">
      {{ errorMessage }}
    </div>

    <label class="textareaLabel"><strong>{{ questionProp.titreImport }}</strong></label>
    <div>
        <Textarea
            @input="onValueChange"
            :autoResize="true"
            v-model="value"
            rows="5"
            cols="30">
        </Textarea>
    </div>

  </template>

<script lang="ts">
import { defineComponent } from 'vue'
import Textarea from 'primevue/textarea';

export default defineComponent({
  name: 'InputTextareaMaison',
  components: {
    Textarea
  },
  data () {
    return {
      errorMessage: '',
      value: ''
    }
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    questionProp: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onValueChange () {
      this.$emit('update:modelValue', this.value);
    },
    validate () {
      // Si la longueur de l'array this.value est de zéro, c'est qu'aucune réponse n'a été fournie.
      if (this.value.length === 0) {
        this.errorMessage = 'Réponse manquante.';
      } else {
        // On s'assure de vider la variable de message d'erreur s'il n'y a pas/plus d'erreur. Autrement, après une première erreur de la part de l'utilisateur, le message d'erreur ne partira jamais.
        this.errorMessage = '';
      }
    }
  }
})
</script>
<style scoped>
  .textareaLabel {
    margin-bottom: 15px;
    margin-top: 35px;
    display: block;
  }
</style>
