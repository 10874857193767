import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41393c4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "panelContainer"
}
const _hoisted_3 = { class: "panelItemContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_EndroitsSelectionnes = _resolveComponent("EndroitsSelectionnes")!
  const _component_Recherche = _resolveComponent("Recherche")!
  const _component_Graphiques = _resolveComponent("Graphiques")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Sidebar, { sectionsAffiche: _ctx.sections }, null, 8, ["sectionsAffiche"]),
      (_ctx.endroitLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.getPageTitle), 1),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.allEndroitsData.length > 0)
                ? (_openBlock(), _createBlock(_component_EndroitsSelectionnes, {
                    key: 0,
                    onBtnEndroitPressed: _ctx.btnEndroitPressed,
                    onBtnRefreshPressed: _ctx.btnRefreshPressed,
                    btnAction: _ctx.endroitsSelectionnesActionBtn
                  }, null, 8, ["onBtnEndroitPressed", "onBtnRefreshPressed", "btnAction"]))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isGraph)
              ? (_openBlock(), _createBlock(_component_Recherche, {
                  key: 0,
                  ref: "usedComponent",
                  allEndroitsData: _ctx.allEndroitsData
                }, null, 8, ["allEndroitsData"]))
              : (_openBlock(), _createBlock(_component_Graphiques, {
                  key: 1,
                  ref: "usedComponent",
                  sections: _ctx.sections,
                  translator: _ctx.translator
                }, null, 8, ["sections", "translator"]))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Footer)
    ])
  ], 64))
}