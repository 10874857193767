<template>
  <div class="container">
      <form class="content">
          <h2>Uploader</h2>
          <Dropdown v-model="categorieDonnee" :options="categorieDonneeOptions" placeholder="Sélectionnez la catégorie de donnée" />
          <Dropdown v-if="categorieDonnee !== ''" @change="onTypeFichierChange" v-model="typeDonneesSelectionnee" :options="typeDeFichier" optionLabel="displayName" placeholder="Sélectionnez un type de fichier" />
          <Dropdown v-if="typeDonneesSelectionnee !== {} && (typeDonneesSelectionnee.id === 'donneesQuebec' || typeDonneesSelectionnee.id === 'donneesISQ')" v-model="subTypeDonneesSelectionnee" :options="sousTypeDeFichier" optionLabel="displayNom" placeholder="Sélectionnez un sous-type de fichier" />
          <FileUpload v-if="typeDonneesSelectionnee !== {}" name="files[]" :showUploadButton="false" :fileLimit="nbFichierAUploader" @select="onUploadFile" @remove="onUploadFile" :multiple="true" />
          <DataTable v-if="fileList.length > 0 && categorieDonnee === categorieDonneeOptions[1]" :value="fileList" editMode="cell" class="editable-cells-table" responsiveLayout="scroll">
            <Column field="nom" header="Nom">
                <template #editor="slotProps">
                  <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                </template>
            </Column>
            <Column v-if="typeDonneesSelectionnee.id === 'total'" columnKey="id" field="role" header="Rôle fichier">
                <template #editor="slotProps">
                  <Dropdown v-model="slotProps.data[slotProps.column.props.field]" optionLabel="displayNom" :options="roleDeFichierRecencement" />
                </template>
                <template #body="slotProps">
                  <span v-if="slotProps.data[slotProps.column.props.field]">{{ slotProps.data[slotProps.column.props.field].id }}</span>
                </template>
            </Column>
            <Column field="ligneHeaders" header="No Lignes headers">
                <template #editor="slotProps">
                  <InputNumber v-model="slotProps.data[slotProps.column.props.field]" />
                </template>
            </Column>
            <!-- <Column field="ligneDebutMetas" header="No Lignes début métadata">
                <template #editor="slotProps">
                  <InputNumber v-model="slotProps.data[slotProps.column.props.field]" />
                </template>
            </Column>
            <Column field="ligneNbMetaLignes" header="Nombre lignes métadata">
                <template #editor="slotProps">
                  <InputNumber v-model="slotProps.data[slotProps.column.props.field]" />
                </template>
            </Column> -->
            <Column field="annee" v-if="typeDonneesSelectionnee.id !== 'donneesISQ'" header="Année">
                <template #editor="slotProps">
                  <InputNumber v-model="slotProps.data[slotProps.column.props.field]" />
                </template>
            </Column>
            <!-- <Column field="role" header="Role fichier" style="width:50%">
                <template #editor="slotProps">
                    <Dropdown v-model="slotProps.data[slotProps.column.props.field]"  :options="roleDeFichierRecencement" optionLabel="displayNom" placeholder="Sélectionnez le role du fichier" />
                </template>
                <template #body="slotProps">
                    <div class="selectCursorOnHover" v-if="slotProps.data[slotProps.column.props.field]">{{ slotProps.data[slotProps.column.props.field].displayNom }}</div>
                    <div class="selectCursorOnHover" v-else>Sélectionnez le rôle du fichier</div>
                </template>
            </Column> -->
          </DataTable>
          <Button label="Soumettre" @click="submit" class="p-button-success" /><br>
          <ProgressSpinner v-if="showSpinner" /> <br> {{ timerAffiche }}
      </form>
      <ConfirmDialog></ConfirmDialog>
      <Button label="Supprimer recensements" @click="resetRecensements" class="p-button-danger" />
      <Button label="Supprimer endroits" @click="resetEndroits" class="p-button-danger" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import TypeDeDonneeRecencement from '../configs/dropdown/Recensements/TypeDeDonneeRecencement.json';
import TypeDeFichierEndroits from '../configs/dropdown/Endroits/TypeDeFichierEndroits.json';
import RecensementsFileTypesPossibles from '../configs/dropdown/Recensements/SubCategoryRecensements/FileTypesPossibles.json';
import FilesTypesPossibleISQ from '../configs/dropdown/Recensements/SubCategoryRecensements/FilesTypesPossibleISQ.json';
import RoleFichierRecensement from '../configs/dropdown/Recensements/RoleFichierRecensement.json';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'Uploader',
  components: {
    FileUpload,
    Button,
    Dropdown,
    DataTable,
    Column,
    InputText,
    ConfirmDialog,
    InputNumber,
    ProgressSpinner
  },
  computed: {
    typeDeFichier () {
      if (this.categorieDonnee === this.categorieDonneeOptions[0]) {
        return TypeDeFichierEndroits;
      } else {
        return TypeDeDonneeRecencement;
      }
    },
    roleDeFichierRecencement () {
      return RoleFichierRecensement;
    },
    sousTypeDeFichier () {
      if (this.typeDonneesSelectionnee.id === 'donneesISQ') {
        return FilesTypesPossibleISQ;
      } else {
        return RecensementsFileTypesPossibles;
      }
    }
  },
  data () {
    return {
      compteurIntervalThread: undefined,
      categorieDonnee: '',
      categorieDonneeOptions: [
        'Endroits',
        'Recensements'
      ],
      subTypeDonneesSelectionnee: {} as any,
      typeDonneesSelectionnee: {} as any,
      nbFichierAUploader: 0,
      timer: 0,
      timerAffiche: '',
      showSpinner: false,
      fileList: new Array<any>()
    }
  },
  methods: {
    onTypeFichierChange () {
      this.nbFichierAUploader = this.typeDonneesSelectionnee.fileUploadLimit
    },
    onUploadFile (event: any) {
      this.fileList = event.files.map((item: File) => {
        return {
          file: item,
          nom: item.name,
          role: '',
          ligneHeaders: 1
          // ligneDebutMetas: 0,
          // ligneNbMetaLignes: 0
        }
      })
    },
    resetRecensements () {
      this.$confirm.require({
        message: 'Voulez-vous supprimer les infos des recensements de la base de données?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('recensement/resetRecensements')
        }
      });
    },
    resetEndroits () {
      this.$confirm.require({
        message: 'Voulez-vous supprimer les infos des endroits de la base de données?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('endroit/deleteEndroits')
        }
      });
    },
    augmenteTimer () {
      this.timer += 1;
      const hr = Math.floor(this.timer / 3600);
      const min = Math.floor((this.timer - hr * 3600) / 60);
      const sec = this.timer % 60;
      this.timerAffiche = hr + ':' + String('0' + min).slice(-2) + ':' + String('0' + sec).slice(-2);
    },
    submit () {
      const isEndroit = this.categorieDonnee === this.categorieDonneeOptions[0];
      const body = new FormData()
      body.append('fileCount', this.fileList.length.toString())
      body.append('typeDonnees', this.typeDonneesSelectionnee.id)
      for (let x = 0; x < this.fileList.length; x++) {
        body.append('file_' + x, this.fileList[x].file)
        if (!isEndroit) {
          body.append('file_' + x + '_headerLine', this.fileList[x].ligneHeaders)
          // body.append('file_' + x + '_metaStart', this.fileList[x].ligneDebutMetas)
          body.append('file_' + x + '_annee', this.fileList[x].annee)
          // body.append('file_' + x + '_metaNb', this.fileList[x].ligneNbMetaLignes)
          if (this.typeDonneesSelectionnee.id === 'total') {
            body.append('file_' + x + '_role', this.fileList[x].role.id)
          }
        }
        if ((this.typeDonneesSelectionnee.id === 'donneesQuebec' || this.typeDonneesSelectionnee.id === 'donneesISQ')) {
          body.append('fileType', this.subTypeDonneesSelectionnee.id)
        }
        // body.append('file_' + x + '_role', this.fileList[x].role)
      }
      let storeRoute = 'recensement/uploadRecensements';
      if (isEndroit) {
        storeRoute = 'endroit/uploadEndroits';
      }
      clearInterval(this.compteurIntervalThread);
      this.timerAffiche = '';
      this.$store.dispatch(storeRoute, body).then(() => {
        clearInterval(this.compteurIntervalThread);
        this.timerAffiche = '';
        this.showSpinner = false;
        this.timer = 0;
      }).catch(() => {
        clearInterval(this.compteurIntervalThread);
        this.showSpinner = false;
        this.timerAffiche = '';
        this.timer = 0;
      });
      this.showSpinner = true;
      this.timer = 0;
      this.compteurIntervalThread = setInterval(this.augmenteTimer, 1000) as any;
    }
  },
  unmounted () {
    // clearInterval();
  }
})
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content {
    min-width: 70%;
    background-color: white;
    padding: 5%;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; */
}
.content > * {
    margin: 2%;
}
</style>
