<template>
  <div class="exportTableContainer">
    <div id="exportTable" class="exportTable">
      <slot name="dataTable" v-bind:headersWithoutFirst="tableauLogique.Colonnes" v-bind:tableauLogique="tableauLogique" v-bind:graphInfo="graphInfo" :titrePremiereLigneTableau="titrePremiereLigneTableau">
        <DataTable :class="panelDouble ? 'tableauDivise' : 'tableau'" :value="tableauLogique.TrueData" responsiveLayout="scroll" showGridlines :rowClass="setBodyClass">
          <template v-if="titreTableau" #header>
            <div class="tableauSection" v-if="titreSection">
                <h2>{{ titreSection }}</h2>
            </div>
            <h2>{{ titreTableau }}</h2>
          </template>
          <ColumnGroup type="header">
            <Row v-if="graphInfo.tableauGroupeInfo">
              <Column colspan="1" v-if="!graphInfo.tableauHideFirstColumn"/>
              <Column v-bind:colspan="tableauLogique.Colonnes.length/2">
                <template #header>
                  <div class="datatableHeaderGroupContainer">{{ graphInfo.tableauGroupeInfo.nomPourcentage }}</div>
                </template>
              </Column>
              <Column v-bind:colspan="tableauLogique.Colonnes.length/2">
                <template #header>
                  <div class="datatableHeaderGroupContainer">{{ graphInfo.tableauGroupeInfo.nomTotal }}</div>
                </template>
              </Column>
            </Row>
            <Row v-else-if="graphInfo.tableauColoneFieldCombinaison">
              <Column colspan="1" v-if="!graphInfo.tableauHideFirstColumn"/>
              <Column v-for="col of tableauLogique.ColonnesTop" :key="col" :colspan="tableauLogique.Colonnes.length/tableauLogique.ColonnesTop.length">
                <template #header>
                  <div class="datatableHeaderContainer">
                    <div class="flexCenter">
                      {{ col }}
                    </div>
                  </div>
                </template>
              </Column>
            </Row>
            <Row>
              <Column v-if="!graphInfo.tableauHideFirstColumn">
                <template #header>
                  {{ titrePremiereLigneTableau }}
                </template>
              </Column>
              <Column v-for="col in tableauLogique.Colonnes" :key="col">
                <template #header>
                  <div class="datatableHeaderContainer">
                    <div>
                      <div v-if="graphInfo.tableauGroupeInfo">{{ removeHeaderPrefix(col) }}</div>
                      <div v-else-if="graphInfo.tableauColoneFieldCombinaison">{{removeHeaderPrefix(col)}}</div>
                      <div v-else-if="graphInfo.keepHeadersOneLine || getNumberLine(col) === 1" >{{col}}</div>
                      <template v-else>
                        <div>{{getFirstLine(col)}}</div>
                        <div>{{getLastLine(col)}}</div>
                      </template>
                    </div>
                  </div>
                </template>
              </Column>
            </Row>
          </ColumnGroup>
          <!-- Titre de la première colonne dans les tableaux standards. Par exemple: "Langue(s)" pour le tableau no 6. -->
          <Column field="valeurPremiereColonne" :sortable="true" v-if="!graphInfo.tableauHideFirstColumn">
            <template #body="test">
              <p v-if="test.data.nomPremiereColonne === 'Dépenses total'" style="font-weight: bold;">{{ test.data.valeurPremiereColonne }}</p>
              <p v-else style="margin:0% !important">{{ test.data.valeurPremiereColonne }}</p>
            </template>
          </Column>
          <!-- <Column v-for="col in colonnes.values()" :field="col.field" :key="col.field" :sortable="true" :sortField="col.field + '_raw'"> -->
          <Column v-for="col in tableauLogique.Colonnes" :field="col" :key="col" :sortable="true" :bodyClass="estNoirci ? 'cell-noirci-container' : null">
            <!-- <template #header="ligne">
                <div class="datatableHeaderContainer">
                  <div class="flexCenter">
                    <div v-if="graphInfo.pourcentageTableau">{{ removeHeaderPrefix(ligne.column.key) }}</div>
                    <div v-else-if="graphInfo.keepHeadersOneLine || getNumberLine(ligne.column.key) === 1">{{ligne.column.key}}</div>
                    <template v-else>
                      <div>{{getFirstLine(ligne.column.key)}}</div>
                      <div>{{getLastLine(ligne.column.key)}}</div>
                    </template>
                  </div>
                </div>
            </template> -->
            <template #body="ligne">
              <div v-if="estNoirci">
                <div v-if="ligne.data[ligne.column.props.field]" class="cell-noirci" style="color:gray;"><span v-if="ligne.data[ligne.column.props.field] != true && ligne.data[ligne.column.props.field] != false">{{removeParentese(ligne.data[ligne.column.props.field])}}</span></div>
              </div>
              <div v-else-if="graphInfo.tableauGroupeInfo" class="datatableValueContainer">
                <div>{{removeParentese(ligne.data[ligne.column.props.field])}}</div>
              </div>
              <template v-else>
                <div v-if="graphInfo.keepValuesOneLine || getNumberLine(ligne.data[ligne.column.props.field]) === 1" class="datatableValueContainer">
                  <div v-if="graphInfo.tableauMaxWith === true" class="maxwidthTableau">{{ligne.data[ligne.column.props.field]}}</div>
                  <div v-else>{{ligne.data[ligne.column.props.field]}}</div>
                </div>
                <div v-else class="datatableValueContainerFlex">
                  <div>
                    <div class="text-bold">{{getFirstLine(ligne.data[ligne.column.props.field])}}</div>
                    <div>{{getLastLine(ligne.data[ligne.column.props.field])}}</div>
                  </div>
                </div>
              </template>
            </template>
          </Column>
        </DataTable>
      </slot>
      <p class="padding-top">
        <!--
          C'est ici qu'il y avait les sources avant. Maintenant, ceci ne sert qu'à donner un "padding" aux endroits appropriés, pour décoller les sources du reste.
        -->
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { TypeTableau } from '@/DTO/Tableau/TypeTableau';
import { TableauLogicHelper } from '../../helpers/TableauLogicHelper';

export default defineComponent({
  name: 'Tableau',
  props: {
    data: {
      type: Object,
      required: true
    },
    panelDouble: Boolean,
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    }
  },
  components: {
    DataTable,
    Column,
    Row,
    ColumnGroup
  },
  data () {
    return {
      tableauLogique: new TableauLogicHelper(this.graphInfo, this.data)
    }
  },
  computed: {
    titreSection () {
      return this.tableauLogique.titreSection();
    },
    titreTableau () {
      return this.tableauLogique.titreTableau();
    },
    titrePremiereLigneTableau (): string {
      return this.tableauLogique.titrePremiereLigneTableau();
    },
    estNoirci () {
      return this.graphInfo.tableauType === TypeTableau.noirci;
    }
  },
  methods: {
    setBodyClass () {
      if (this.estNoirci) {
        return 'row-noirci';
      }
      return '';
    },
    removeParentese (str: string): string {
      return typeof str === 'string' ? str.replace(/\(|\)/g, '') : str;
    },
    removePrefix (str: string, prefix: string): string {
      if (str.startsWith(prefix)) {
        return str.slice(prefix.length);
      }
      return str;
    },
    removeHeaderPrefix (str: string): string {
      // if (str === undefined) {
      //   return '';
      // }
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        const prefixPos = str.indexOf('_');
        if (prefixPos !== -1) {
          return str.substring(0, prefixPos);
        }
        return str;
      }
      return this.removePrefix(this.removePrefix(str, 'total-'), 'pourcentage-');
    },
    getNumberLine (text: string) {
      return this.tableauLogique.getNumberLine(text);
    },
    getFirstLine (text: string | number) {
      return this.tableauLogique.getFirstLine(text);
    },
    getLastLine (text: string | number) {
      return this.tableauLogique.getLastLine(text);
    }
  },
  created () {
    this.tableauLogique.init();
  }
})
</script>
<style>
  .row-noirci .cell-noirci-container:has(.cell-noirci){
    background-color: #2A4DA1;
  }
</style>
<style scoped lang="scss">
  .tableauSection{
    border-bottom: 1px solid #e9ecef;
  }
  .maxwidthTableau {
    max-width: 450px;
    overflow: visible;
    word-wrap: break-word;
    white-space: normal;
  }
  .td {
    padding: 1% 1rem !important;
  }
  .flexCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .datatableHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .datatableHeaderGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .datatableValueContainerFlex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  // Pour ajuster l'en-tête du tableau non-simplifié. C'est nécessaire pour que les titres arrivent à la même hauteur d'un onglet à l'autre et en particulier à l'intérieur même de l'onglet Graphique et tableau. Voir billet 639 pour plus d'infos.
  h2 {
    font-size: 25px;
    margin: 0;
    margin-top: 3px;
    padding: 1rem;
    // Pour que le titre du tableau et le titre du graphique soient à la même hauteur sous Chrome, car tout était bien sous Firefox, mais pas sous Chrome.
    margin-top: -5px;
    // Code commenté conservé volontairement. En effet, il y a eu un moment où le décentrage du titre dans les tableaux ne se faisait que sous Chrome, mais pas sous Firefox, puis le bogue est apparu aussi sous Firefox.
    // -webkit-margin-before: -5px;
  }
  .exportTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .padding-top {
    padding-top: 35px;
  }
  .tableauDivise {
    overflow-x: auto;
    flex-basis: 50%;
  }
  .tableau {
    width: 100%;
  }
  .exportTable {
    max-width: 100%;
  }

  .text-bold {
    font-weight: bold;
  }

  .datatableValueContainer {
    white-space: nowrap !important;
  }
  // Commenté le 2022-04-21 pour permettre au tableau dans Graphique et tableau de prendre plus de largeur.
  // .exportTable {
  //   width: 100%;

  //   // flex
  //   display: flex;
  //   justify-content: center;
  // }
</style>

<style lang="scss">
  // Paramètre "scoped" absent ici comme on veut parler à des petits-enfants.

  // Alignement des chiffres à droite dans les tableaux pour une lecture plus facile. Voir billet 637 pour plus d'infos. La mention "important" est nécessaire ici.
  td:not(:first-child), th:not(:first-child) {
    text-align: right !important;
  }
  // Alignement du titre des colonnes comportant des chiffres (en d'autres termes: tout ce qui n'est pas la première colonne) à droite également. Utilisation du flex comme "text-align: right" ne fonctionnait pas.
  th:not(:first-child) > div {
    display: flex;
    justify-content: flex-end;
  }

  // Pour ajuster l'en-tête du tableau non simplifié. C'est nécessaire pour que les titres arrivent à la même hauteur d'un onglet à l'autre et en particulier à l'intérieur même de l'onglet Graphique et tableau. Voir billet 639 pour plus d'infos.
  .p-datatable-header {
    background: #F8F9FA !important;
    box-sizing: content-box !important;
    padding: 0 !important;
  }
</style>
