<template>
    <!--
        Boucle générant les sources sur une seule ligne (sans retour de chariot)
        Cette boucle sert non seulement pour les graphiques, mais également pour les tableaux.
    -->
    <div class="graphique-source-layout" v-if="graphInfoProp.sources">
      <a target="_blank" :href="source.lien" v-for="(source, index) in sources" :key="source">
        <!-- Si ce n'est pas la dernière source, afficher une ',' -->
        {{source.reference}} {{index == sources.length -1 ? source.annee : source.annee + ','}}
      </a>
    </div>
    <!-- sources questionnaires -->
    <div class="graphique-source-layout graphique-tarte-source" v-if="graphInfoProp.questionnaireSources">
        {{ questionnaireSources }}
    </div>
    <!-- sources questionnaire tableau -->
    <div class="graphique-source-layout" v-if="graphInfoProp.questionnaireTableauSources">
       {{ questionnaireTableauSources }}
    </div>
</template>

<script lang="ts">

import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import questionnaire from '@/store/questionnaire';
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: 'GraphSources',
  props: {
    data: {
      type: Object as PropType<FormatedGraphiqueDataItemDTO>
    },
    graphInfoProp: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      endroits: new Array<string>()
    }
  },
  computed: {
    /*
      Liste des sources à afficher formatée
    */
    sources () {
      // Cela sert à faire en sorte que seulement le premier lien ait la référence, et les autres afficheront seulement l'année (exemple: Statistique Canada, 2006, 2012, 2017)
      let derniereReference: string | undefined;
      return this.graphInfoProp.sources.map((source: any) => {
        // Si lui avant avait la même référence, on enlève la référence de celui-ci
        if (derniereReference === source.reference) {
          source.reference = ''
        // Si c'est une nouvelle référence, on remplace la variable derniereReference
        } else {
          derniereReference = source.reference;
        }
        return source;
      });
    },
    questionnaireSources () {
      return 'Liste des répondants: ' + this.endroits + ' (' + this.endroits.length + ' répondants)';
      // return 'Répondants: (' + this.endroits + ') (' + this.endroits.length + ' répondants)';
    },
    questionnaireTableauSources () {
      var data = Array.from(this.data?.data as any)[0] as any;
      var trueData = data[1];
      var date = trueData.find((x:any) => x.theme === 'Date de complétion');
      if (date !== undefined) {
        date = date.total;
      } else {
        // test to find the date in last element of truedata
        var dataFull = Array.from(this.data?.data as any) as any;
        const lastData = dataFull[dataFull.length - 1];
        const lastDataValue = lastData[1];
        date = lastDataValue.find((x:any) => x.theme === 'Date de complétion');
        if (date !== undefined) {
          date = date.total;
        }
      }

      var allEndroitsData = this.$store.getters['endroit/getEndroits'];
      var endroitData = data[0];
      if (endroitData !== undefined) {
        endroitData = allEndroitsData.find((x:any) => x._id === endroitData)?.NOM_GEO;
      }

      if (endroitData === undefined) {
        // test to find the data in the last element of truedata
        var dataFull2 = Array.from(this.data?.data as any) as any;
        const lastData = dataFull2[dataFull2.length - 1];
        const lastDataValue = lastData[1];
        endroitData = lastDataValue[0]?.endroit?._id;
        if (endroitData !== undefined) {
          endroitData = allEndroitsData.find((x:any) => x._id === endroitData)?.NOM_GEO;
        }
      }
      return endroitData + ', complété le ' + date;
    }
  },
  mounted () {
    var allEndroitsData = this.$store.getters['endroit/getEndroits'];
    var endroitsData = this.data?.endroits as Array<string>;
    if (endroitsData !== undefined) {
      endroitsData.forEach(element => {
        var endroit = allEndroitsData.find((x:any) => x._id === element)?.NOM_GEO;
        this.endroits.push(endroit);
      });
    }

    this.endroits = this.endroits.sort();
  }
})
</script>

<style lang="scss" scoped>
.graphique-source-layout {
    font-family: 'Encode Sans', Avenir, Helvetica, Arial, sans-serif;
    font-size: 11px;
    text-align: left;
    text-decoration: none;

    a {
      padding-right: 3px;
        text-decoration: none;

        // même couleur, que le lien soit visité ou non
        &:link, &:active {
            color: black;
        }

        &:focus, &:hover, &:visited {
            color: black;
        }
    }
}

.graphique-tarte-source {
  margin-top: 15px;
}
</style>
