<template>
  <InputText
    @input="$emit('update:modelValue', $event.target.value)"
    :pattern="generePattern"
    :placeholder="placeholderProp"
    required=false
    :type="typeProp"
    :value="modelValue"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'InputTextMaison',
  components: {
    InputText
  },
  data () {
    return {
      pattern: ''
    }
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholderProp: {
      type: String,
      required: true
    },
    typeProp: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    generePattern () {
      const emailPattern = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';
      const textPatternOptionnel = '^.+?$';
      // textPatternRequired sert pour les prénoms et les noms; les traits d'union et les espaces sont possibles dans les noms et les prénoms (Jean Le Rond d’Alembert, etc.); les signes diacritiques (accents, cédille, tréma, etc.) sont tous admis; la ponctuation n'est pas admise, sauf l'apostrophe, courte ou droite, qui peut se retrouver dans certains noms de famille, comme "L'Heureux"
      const textPatternRequired = "[a-z|A-Z|À-ÿ|\\-|\\s|'|’]+";

      // courriel
      if (this.typeProp === 'email') {
        return emailPattern;
      // texte - noms, prénoms, etc. (rares signes de ponctuation admis)
      } else if (this.typeProp === 'text-required') {
        return textPatternRequired;
      // texte - champs optionnels - il y a actuellement une seule question optionnelle, marquée "text-optionnel", dans nos questionnaires
      } else {
        return textPatternOptionnel;
      }
    }
  }
})
</script>
