<template>
  <div>
    <ProgressSpinner class="loadingSpinner" style="width:50px;height:50px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'Loading',
  components: {
    ProgressSpinner
  }
})
</script>
<style lang="scss">
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #F0753B;
    }
    40% {
        stroke: #F0753B;
    }
    66% {
        stroke: #F0753B;
    }
    80%,
    90% {
        stroke: #F0753B;
    }
}
</style>
