<template>
  <div class="rechercheMapContainer">
    <div class="filtre">
      <span class="titreFiltre"
        >RECHERCHER DES MUNICIPALITÉS SUR LA CARTE&nbsp;: <span class="txtEndroitTitre">{{ endroitHoveredNom }}</span></span
      >
      <hr />
      <div class="carteContainer">
        <div class="carteTarget" id="carteTarget"></div>
        <div v-show="isLoading" class="carteTarget loading"><div>Chargement... ({{ chargement.current }}/{{ chargement.total }})</div></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from 'vue';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { Map as OLMap, View } from 'ol';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import geojsonObject from '../../public/mapData/data/regio_s.json';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { EndroitType } from '../DTO/EndroitType';
import { EndroitDTO } from '@/DTO/EndroitDTO';
import 'ol/ol.css'
import { FeatureLike } from 'ol/Feature';

export default defineComponent({
  name: 'RechercheEndroitCarte',
  emits: ['onEndroitUpdated'],
  data () {
    const strokeColor = 'rgb(119, 119, 119)';
    const selectedStrokeColor = 'green';
    const hoverStrokeColor = 'rgb(82, 121, 163)';
    const fillColor = 'rgba(119, 119, 119, 0.5)';
    const selectedFillColor = 'rgba(235, 255, 227, 0.7)';
    const hoverFillColor = 'rgba(82, 121, 163, 0.7)';
    return {
      center: [-70, 50],
      projection: 'EPSG:4326',
      zoom: 6,
      chargement: {
        total: 0,
        current: 0
      },
      rotation: 0,
      map: null as unknown as OLMap,
      isLoading: false,
      endroitHovered: null as any,
      endroitHoveredNom: '',
      endroitDataPerTypeAndNumero: new Map<string, EndroitDTO>(),
      endroitSelected: new Set<string>(),
      normalStyle: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 3
        }),
        fill: new Fill({
          color: fillColor
        })
      }),
      emptyNormalStyle: new Style({
        stroke: new Stroke({
          color: 'rgb(70, 70, 70)',
          width: 3
        })
      }),
      emptierNormalStyle: new Style({
        stroke: new Stroke({
          color: 'black',
          width: 3
        })
      }),
      hoveredStyle: new Style({
        stroke: new Stroke({
          color: hoverStrokeColor,
          width: 3
        }),
        fill: new Fill({
          color: hoverFillColor
        })
      }),
      selectedStyle: new Style({
        stroke: new Stroke({
          color: selectedStrokeColor,
          width: 3
        }),
        fill: new Fill({
          color: selectedFillColor
        })
      })
    };
  },
  setup () {
    const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>
    return {
      endroitSelectionHelper
    }
  },
  props: {
    endroitsData: {
      type: Array as PropType<Array<EndroitDTO>>,
      required: true
    }
  },
  methods: {
    updateEndroitSelected () {
      this.endroitSelected = new Set<string>(this.endroitSelectionHelper.getAllSelectedEndroits());
      this.map.changed();
      this.$loading.stopLoading();
    },
    onMapClick (event: any, map: OLMap) {
      this.$loading.startLoading();
      map.forEachFeatureAtPixel(event.pixel, async (feature: any) => {
        const endroit = feature.get('endroit');
        if (endroit !== undefined) {
          await this.endroitSelectionHelper.addEndroitAndHandleType(endroit, this.$toast, this.$store);
          // this.updateEndroitSelected();
          this.$emit('onEndroitUpdated');
        }
        return true;
      });
    },
    getIdFromFeatureDependingEndroitType (feature: FeatureLike, endroitType: EndroitType) {
      let endroitIDfieldname = ''
      switch (endroitType) {
        case EndroitType.Region:
          endroitIDfieldname = 'RES_CO_REG'
          break
        case EndroitType.MRC:
          endroitIDfieldname = 'MRS_CO_MRC'
          break
        case EndroitType.municipalite:
          endroitIDfieldname = 'MUS_CO_GEO'
          break
      }
      return feature.get(endroitIDfieldname)
    },
    getKeyFromFeatureAndType (feature: FeatureLike, endroitType: EndroitType): string {
      return endroitType + '_' + this.getIdFromFeatureDependingEndroitType(feature, endroitType)
    },
    getEndroitFromFeatureAndType (feature: FeatureLike, endroitType: EndroitType) {
      return this.endroitDataPerTypeAndNumero.get(this.getKeyFromFeatureAndType(feature, endroitType));
    },
    styleEmptyFunction (feature: FeatureLike) {
      const endroit = feature.get('endroit') as EndroitDTO;
      if (endroit && this.endroitSelected.has(endroit._id)) {
        return this.selectedStyle as Style;
      } else {
        return this.emptyNormalStyle as Style;
      }
    },
    styleEmptierFunction (feature: FeatureLike) {
      const endroit = feature.get('endroit') as EndroitDTO;
      if (endroit && this.endroitSelected.has(endroit._id)) {
        return this.selectedStyle as Style;
      } else {
        return this.emptierNormalStyle as Style;
      }
    },
    styleFunction (feature: FeatureLike): Style {
      const endroit = feature.get('endroit') as EndroitDTO;
      if (endroit && this.endroitSelected.has(endroit._id)) {
        return this.selectedStyle as Style;
      } else {
        return this.normalStyle as Style;
      }
    },
    onPointerMove (e: any, map: OLMap) {
      if (this.endroitHovered !== null) {
        // this.endroitHovered.changed();
        this.endroitHovered.setStyle(this.endroitHovered.get('fonctionStyle')(this.endroitHovered));
        this.endroitHovered = null;
      }
      let lowestFeature: any = null;
      map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
        if (lowestFeature === null) {
          feature.setStyle(feature.get('fonctionStyle')(feature));
        }
        lowestFeature = feature;
        // return true;
      });
      if (lowestFeature !== null) {
        this.endroitHovered = lowestFeature;
        lowestFeature.setStyle(this.hoveredStyle as Style);
      }

      if (this.endroitHovered) {
        const endroit = this.endroitHovered.get('endroit') as EndroitDTO;
        if (endroit) {
          this.endroitHoveredNom = endroit.NOM_GEO;
        } else {
          this.endroitHoveredNom = 'Inconnu';
        }
      } else {
        this.endroitHoveredNom = '';
      }
    },
    getVectorLayerFromJson (jsonItem: any, endroitType: EndroitType, zIndex: number, fonctionStyle: (feature: FeatureLike) => Style, minZoom: number, maxZoom?: number | undefined): VectorLayer<VectorSource> {
      const features = (new GeoJSON()).readFeatures(jsonItem, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).map((feature) => {
        feature.set('endroit', this.getEndroitFromFeatureAndType(feature, endroitType));
        feature.set('endroitType', endroitType);
        feature.set('fonctionStyle', fonctionStyle);
        return feature;
      });
      const vectorSource = new VectorSource({
        features
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: fonctionStyle,
        minZoom,
        zIndex,
        properties: {
          endroitType
        }
      });
      if (maxZoom !== undefined) {
        vectorLayer.setMaxZoom(maxZoom);
      }
      return vectorLayer;
    }
  },
  async mounted () {
    this.isLoading = true;
    this.endroitDataPerTypeAndNumero = new Map<string, EndroitDTO>()
    this.endroitsData.forEach((endroitData: EndroitDTO) => {
      if (endroitData.noEndroitCarte !== undefined && endroitData.typeEndroit !== undefined) {
        this.endroitDataPerTypeAndNumero.set(endroitData.typeEndroit + '_' + endroitData.noEndroitCarte, endroitData);
      }
    });
    const regionLayer = this.getVectorLayerFromJson(geojsonObject, EndroitType.Region, 300, this.styleFunction, 0, 6);
    const regionEmptyLayer = this.getVectorLayerFromJson(geojsonObject, EndroitType.Region, 300, this.styleEmptierFunction, 6, undefined);
    const muniLayer = new Array<VectorLayer<VectorSource>>();
    const mrcLayers = new Array<VectorLayer<VectorSource>>();
    const mrcEmptyLayers = new Array<VectorLayer<VectorSource>>();
    const regionAlreadyImported = new Set<string>();
    const mrcAlreadyImported = new Set<string>();
    this.chargement.total = geojsonObject.features.length;
    for (const feat of geojsonObject.features) {
      const regionId = feat.properties.RES_CO_REG;
      if (!regionAlreadyImported.has(regionId)) {
        regionAlreadyImported.add(regionId);
        const mrcJson = await import(`../../public/mapData/data/mrc/mrc_s_${regionId}.json`);
        mrcLayers.push(this.getVectorLayerFromJson(mrcJson, EndroitType.MRC, 200, this.styleFunction, 6, 8));
        mrcEmptyLayers.push(this.getVectorLayerFromJson(mrcJson, EndroitType.MRC, 200, this.styleEmptyFunction, 8, undefined));
        for (const mrcItem of mrcJson.features) {
          const mrcId = mrcItem.properties.MRS_CO_MRC;
          if (!mrcAlreadyImported.has(mrcId)) {
            mrcAlreadyImported.add(mrcId);
            const muniJson = await import(`../../public/mapData/data/munic/munic_s_${mrcId}.json`);
            muniLayer.push(this.getVectorLayerFromJson(muniJson, EndroitType.municipalite, 100, this.styleFunction, 8));
          }
        }
      }
      this.chargement.current++;
    }
    const map = new OLMap({
      target: 'carteTarget', // (this.$refs.carteTarget as HTMLDivElement),
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        regionLayer,
        regionEmptyLayer,
        ...mrcLayers,
        ...mrcEmptyLayers,
        ...muniLayer
      ],
      view: new View({
        center: [-7.75e6, 6.1e6],
        zoom: 6
      })
    });
    this.map = map;
    map.on('click', (event) => {
      this.onMapClick(event, map);
    });
    map.on('pointermove', (e) => {
      this.onPointerMove(e, map);
    });
    // map.getView().on('change:resolution', () => {
    //   regionLayer.changed();
    //   mrcLayers.forEach((mrcLayer) => {
    //     mrcLayer.changed();
    //   });
    //   map.renderSync();
    // });

    this.updateEndroitSelected();
    this.isLoading = false;
  }
});
</script>

<style lang="scss" scoped>

  .txtEndroitTitre {
  color: #5C72AF
  }
  .popover {
    background-color: 'green';
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .titreFiltre {
    font-weight: 600;
    text-transform: uppercase;
  }

  .filtre {
    text-align:left;
  }
  .carteTarget {
    width: 100%;
    height: 600px;
  }
  .carteContainer {
    position: relative;
  }
</style>
