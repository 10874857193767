<template>
  <div class="graph" ref="chartdiv"></div>
  yesysysysys
</template>

<script lang="ts">
import { defineComponent, PropType, inject, Ref } from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import couleurs from '@/configs/couleurs.json';
import am4themesKelly from '@amcharts/amcharts4/themes/kelly';
import { FormatedGraphiqueDataItemDTO } from '@/DTO/FormatedGraphiqueDataItemDTO';
import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { GraphErrorDTO } from '@/DTO/GraphErrorDTO';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';

function am4themesMyTheme (target: any) {
  // On crée le spectre de couleurs pour le chart avec les couleurs dans le fichier de config
  if (target instanceof am4core.ColorSet) {
    target.list = couleurs.map((couleur) => am4core.color(couleur));
  }
}

export default defineComponent({
  name: 'GraphiqueStacked',
  props: {
    data: {
      type: Object as PropType<FormatedGraphiqueDataItemDTO>,
      required: true
    },
    graphInfo: {
      type: Object as PropType<DescriptionGraphiqueDTO>,
      required: true
    },
    panelDouble: Boolean,
    fieldValue: Array,
    graphAxisName: Object
  },
  data () {
    return {
      chart: {} as am4charts.PieChart,
      chartData: {} as Map<string, Array<RecensementDataItem>>,
      errors: {} as Map<string, GraphErrorDTO>
    };
  },
  methods: {
    createGraph () {
      am4core.options.autoSetClassName = true;
      (this.$refs.chartdiv as HTMLElement).innerHTML = '';
      const targetDiv = document.createElement('div') as HTMLDivElement;
      let heightMultiplier = 1;
      if (this.graphInfo.heightMultiplier !== undefined) {
        heightMultiplier = this.graphInfo.heightMultiplier;
      }
      targetDiv.style.minHeight = 600 * heightMultiplier + 'px';
      (this.$refs.chartdiv as HTMLElement).appendChild(targetDiv);
      const chart = am4core.create(targetDiv, am4charts.PieChart);
      // // Pour empêcher que le dernier libellé de l'axe des X soit tronqué en fin de ligne et devienne partialement invisible. Voir billets 631 (condanmné en raison d'un bogue étrange de Git) et 646 à ce sujet au besoin. Si on désire changer cela dans GraphiqueABande.vue, il serait logique de changer aussi GraphiqueACourbe.vue.
      // // chart.paddingRight = 30;
      // chart.data = Array.from(this.chartData.values()).flat();
      // // création et configuration de la légende
      // chart.legend = new am4charts.Legend();
      // chart.legend.valueLabels.template.text = '';
      // // chart.maskBullets = false;
      // // chart.zoomOutButton.disabled = true;
      // chart.language.locale._thousandSeparator = ' ';
      // const title = chart.titles.create();
      // // c'est ici que ça se passe pour le style des titres
      // // si on modifie les titres dans GraphiqueABande.vue, il faut le faire aussi dans GraphiqueACourbe.vue pour que l'appli soit uniforme
      // // le secret est d'utiliser la propriété .maxWidth, qui doit être une taille fournie en pixels
      // title.marginTop = 14;
      // title.text = '[bold #495057]' + (this.graphInfo.name as string) + '[/]';
      // title.text = title.text.toLocaleUpperCase();
      // title.fontSize = 25;
      // title.marginBottom = 30;
      // title.wrap = true;
      // title.truncate = false;
      // title.maxHeight = 500;
      // title.textAlign = 'middle';
      // // Pour que le title.wrap s'applique, quand la fenêtre change ça re-dessine le title
      // chart.events.on('sizechanged', function () {
      //   title.deepInvalidate();
      // });
      // this.setSpacingParameters(chart, title);
      // this.chart = chart;
    },
    /*
      Setup les paramètre d'un graphiques pour l'espacement de celui-ci
    */
    setSpacingParameters (chart: am4charts.PieChart, title: am4core.Label) {
      chart.height = am4core.percent(100);
      title.marginBottom = 5;
    },
    generateGraphs () {
      this.createGraph();
      this.createSeries();
    },
    createSeries () {
      // const chart = this.chart;
      // const pieSeries = chart.series.push(new am4charts.PieSeries());
      // pieSeries.dataFields.value = 'total';
      // pieSeries.dataFields.category = this.graphInfo.themeField
      //   ? this.graphInfo.themeField
      //   : 'reponse';
      // pieSeries.labels.template.text =
      //   pieSeries.labels.template.text + ' ({value.value} rép.)';
      // pieSeries.labels.template.wrap = true;
      // pieSeries.labels.template.maxWidth = 150;
    }
  },
  mounted () {
    this.chartData = this.data.data;
    this.errors = this.data.errors;
    this.generateGraphs();
  },
  created () {
    am4core.useTheme(am4themesMyTheme);
    // am4core.useTheme(am4themesKelly);
  }
});
</script>

<style scoped lang="scss">
.graph {
  margin: 0 auto;
  align-items: center;
  width: 100%;
}
.graphique_et_tableau .graph {
  width: 85%;
}
.graph > div {
  height: 100%;
}
.flex > .graph {
  flex-basis: 50%;
}
.source-line:last-of-type {
  padding-bottom: 10px;
}
</style>
