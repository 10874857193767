<template>
<Header></Header>
  <div class="home">
    <Sidebar :sectionsAffiche="sections"></Sidebar>
    <div v-if="endroitLoaded" class="panelContainer">

      <h1>{{ getPageTitle }}</h1>

      <div class="panelItemContainer">
        <EndroitsSelectionnes @btnEndroitPressed="btnEndroitPressed" @btnRefreshPressed="btnRefreshPressed" v-if="allEndroitsData.length > 0" :btnAction="endroitsSelectionnesActionBtn" />
      </div>
      <Recherche ref="usedComponent" v-if="!isGraph" :allEndroitsData="allEndroitsData" />
      <Graphiques ref="usedComponent" v-else :sections="sections" :translator="translator" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue'
import Sidebar from '@/components/Sidebar.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Recherche from './Recherche.vue'
import Graphiques from './Graphiques.vue'
import { ValueTranslator } from '@/traduction/ValueTranslator'
import { UrlQueryDeserialiser } from '../helpers/UrlQueryDeserialiser'
import EndroitsSelectionnes from '@/components/EndroitsSelectionnes.vue'
import { EndroitDTO } from '@/DTO/EndroitDTO'
import { RouteLocationNormalizedLoaded, useRouter, useRoute } from 'vue-router'
import { ActionBtn } from '@/DTO/ActionBtn'
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper'
import Menu from '../configs/Menu.json'
// import GlobalParamsHandlers from '../globalParams/GlobalParamsHandlers'
import GlobalParamsHandlers from '../globalParams/GlobalParamsHandlers'
import { MenuSectionItem } from '../DTO/MenuSectionItem';
import { AxiosError } from 'axios'

export default defineComponent({
  name: 'Home',
  computed: {
    isGraph (): boolean {
      return this.$route.meta.isGraph as boolean;
    },
    endroitsSelectionnesActionBtn (): ActionBtn {
      if (this.isGraph) {
        return new ActionBtn('Ajouter une municipalité', () => { this.goToLinkWithQueryParams('Rechercher-Endroit') });
      } else {
        return new ActionBtn('Afficher les statistiques', () => { this.goToLinkWithQueryParams('Home') });
      }
    },
    getPageTitle () {
      return GlobalParamsHandlers.pageTitle;
    }
  },
  methods: {
    conversionStringANum (val: string) {
      return Number.parseInt(val);
    },
    btnEndroitPressed () {
      if (this.$refs.usedComponent !== null && (this.$refs.usedComponent as any).btnEndroitPressed) {
        (this.$refs.usedComponent as any).btnEndroitPressed();
      }
    },
    btnRefreshPressed () {
      if ((this.$refs.usedComponent as any).btnRefreshPressed) {
        (this.$refs.usedComponent as any).btnRefreshPressed();
      }
    },
    updateEndroits (route: RouteLocationNormalizedLoaded) {
      this.endroits = this.endroitSelectionHelper.getAllEndroitsWithoutConsideringActivationStatus().map((val: string) => {
        return this.$store.getters['endroit/getEndroit'](val);
      })
      this.endroitsDesactives = this.urlQueryDeserialiser.getArrayFromRoute(route, 'endroitsDesactives', this.conversionStringANum)
    },
    goToLinkWithQueryParams (routeName: string) {
      const params = this.$route.params;
      this.$router.push({ name: routeName, params, query: this.$route.query })
    },
    getAllIdsFromNodes (node: any): Array<number> {
      let ids = new Array<number>();
      if ('to' in node && node.to.params.idListData !== 'tout') {
        ids.push(node.to.params.idListData);
      }
      if ('items' in node) {
        node.items.forEach((innerNode: any) => {
          ids = ids.concat(this.getAllIdsFromNodes(innerNode))
        });
      }
      return ids;
    }
  },
  components: {
    Sidebar,
    Header,
    Footer,
    Graphiques,
    Recherche,
    EndroitsSelectionnes
  },
  watch: {
    '$route' (to, from) {
      this.updateEndroits(to);
    }
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const endroits = ref(new Array<EndroitDTO>());
    const endroitsDesactives = ref(new Array<number>());
    const urlQueryDeserialiser = new UrlQueryDeserialiser();
    const endroitSelectionHelper = ref(new EndroitSelectionHelper(urlQueryDeserialiser, route, router));

    provide('endroits', endroits);
    provide('endroitsDesactives', endroitsDesactives);
    provide('endroitSelectionHelper', endroitSelectionHelper);

    return {
      endroits,
      endroitsDesactives,
      urlQueryDeserialiser,
      endroitSelectionHelper,
      allEndroitsData: new Array<any>(),
      sections: new Array<MenuSectionItem>(),
      translator: new ValueTranslator(),
      endroitLoaded: ref(false)
    }
  },
  created () {
    if (this.$route.params.section !== undefined) {
      const sectionName = this.$route.params.section as string;
      if (sectionName === 'tout') {
        Menu.forEach(section => {
          if ('items' in section) {
            const graphsId = section.items!.map(graph => graph.to.params.idListData)
            this.sections.push({ nom: sectionName, graphsId });
          }
        });
      } else {
        const section = Menu.find((sect) => sect.name === sectionName);
        if (section !== undefined && 'items' in section) {
          const graphsId = section.items!.map(graph => graph.to.params.idListData)
          this.sections.push({ nom: sectionName, graphsId });
        }
      }
    }
  },
  mounted () {
    this.$loading.startLoading();
    this.$store.dispatch('endroit/getAllEndroits').then(() => {
      this.allEndroitsData = this.$store.getters['endroit/getEndroits'];
      this.updateEndroits(this.$route);
      this.endroitLoaded = true;
    }).catch((err: AxiosError) => {
      if (err.response?.status === 429) {
        this.$toast.error('Trop de requêtes ont été faites, veuillez attendre 1 minute', { timeout: 60000 });
        console.warn('Too many requests');
      }
    });
  }
})
</script>
<style lang="scss" scoped>
  h1 {
    color: #001B33;
    font-weight: normal;
    // Pour que la marge soit à la fois la même en hauteur et en largeur et qu'elle soit aussi la même que pour les autres éléments de la page.
    margin: 2.5vw 2.5vw;
    text-align: left;
  }

  .home {
    display: flex;
    flex-direction: row;
    min-height: 93vh;
  }

  .panelContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .panelItemContainer > div {
    width: 100%;
  }

  ::v-deep(.panelItemContainer) {
    height: fit-content;
    width: 95%;
    margin: 1vh 2.5vw;
    padding: 2.5%;
    padding-bottom: 0px;
    border-radius: 10px;
    background-color: white;
    position: relative;
  }

</style>
