<template>
  <div class="flex-container">
      <div class="loginContainer">
            <div class="container">
              <div>
                <div>
                  <img id="logo" src="/images/logo-RURLS.png" alt="">
                </div>
                <div>
                  <h2>CRÉER UN NOUVEAU MOT DE PASSE</h2>
                </div>
                <div class="error" v-if="errorMessage != ''">
                  {{errorMessage}}
                </div>
                <div class="success" v-if="successMessage != ''">
                  {{successMessage}}
                </div>
                <div>
                  <label for="password">Nouveau mot de passe</label>
                  <input type="password" toggleMask="true" placeholder="Entrez le nouveau mot de passe" name="password" v-model="v$.password.$model" required>
                </div>
                <div>
                  <label for="repassword">Retaper le nouveau mot de passe</label>
                  <input type="password" toggleMask="true"  name="repassword" placeholder="Confirmez le nouveau mot de passe" v-model="v$.repassword.$model" required>
                </div>
                <div>
                  <button type="submit" @click="onSubmit">Créer</button>
                </div>
              </div>
            </div>
      </div>
      <div class="right-side">
        <img src="../../public/images/fond-reseau.png"/>
      </div>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default defineComponent({
  name: 'ResetPassword',
  setup () {
    const state = reactive({
      password: '',
      repassword: ''
    })
    const rules = {
      password: { required },
      repassword: { required }
    }

    const v$ = useVuelidate(rules, state)

    return { v$, state }
  },
  data () {
    return {
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    validateForm () {
      this.errorMessage = '';
      var invalid : boolean = this.v$.password.$invalid === false ? this.v$.repassword.$invalid : this.v$.password.$invalid;
      if (invalid === true) {
        const error = this.v$.password.$errors[0] === undefined ? this.v$.repassword.$errors[0] : this.v$.password.$errors[0];
        if (error.$validator === 'required') {
          this.errorMessage = 'Veuillez remplir tous les champs';
        }
      } else if (this.state.password !== this.state.repassword) {
        invalid = true;
        this.errorMessage = 'Les mots de passe entrés sont différents'
      }

      return !invalid;
    },
    onSubmit () {
      this.successMessage = '';
      this.v$.password.$touch();
      this.v$.repassword.$touch();
      const credentials = { token: this.$route.params.resetToken, newPassword: this.state.password }
      if (!this.validateForm()) return;
      this.$store.dispatch('auth/resetPassword', credentials).then(
        () => {
          this.successMessage = 'Votre mot de passe a été modifié avec succès!';
        },
        () => {
          this.errorMessage = 'Une erreur est survenue lors de l\'envoi de votre requête';
        }
      );
    }
  }
})
</script>

<style scoped>
html { height:100%; }
body {
  height:100%;
  width:100%;
}
#app{height:100%;}
.flex-container{
display:flex;
flex-direction: row;
background-color:white;
height:100%;
}

.error{
  color:red;
  background-color: #FFEDED;
  width:100%;
  font-weight: 400;
  font-size:14px;
  text-align:center;
  margin-bottom:15px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

.success {
  color:#003300;
  background-color: #ccffcc;
  width:100%;
  font-weight: 400;
  font-size:14px;
  text-align:center;
  margin-bottom:15px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left: 15px;
  padding-right: 15px;
}

a{
  color: #5C72AF;
  font-size:14px;
  font-weight: 400;
  cursor: pointer;
}

.loginContainer {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:white;
}

.container > div {
  margin: auto;
}

.right-side {
  width: 65%;
  background-image: linear-gradient(to left,#001B33,#001B33C9,#38A295);
  opacity:1;
}

.right-side img {
  width: 88%;
  height: 99.63%;
  object-fit: cover;
  float:right;
}

.gradient{
  width:27%;
  background: transparent linear-gradient(0deg, var(--unnamed-color-ffffff) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
}

form {
    width: 100%;
    height:100%;
    background-color: white;
}

label{
  font-size:14px;
  color:#F0753B;
  font-weight: 500;
}
/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: 400;
}

img#logo{
  width: 70%;
  margin: 0 auto ;
}

h1{
  font-size: 36px;
  letter-spacing: 0.15px;
  color: #F0753B;
  margin-top: 15%;
  margin-bottom: 15%;
  width:100%;
  font-weight:400;
}

h2{
  font-size: 16px;
  text-transform: uppercase;
  color: black;
  font-weight:600;
  letter-spacing: 0.15px;
}

/* Set a style for all buttons */
button {
  background : #F0753B 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 4px;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  font-size:14px;
}

button:hover {
  opacity: 0.8;
}

input{
    border: 0 !important;
    outline: 0;
    border-bottom: 1px solid #4F5866 !important;
    text-align:center;
    margin-top:7.5%;
    margin-bottom:7.5%;
}
/* Extra styles for the cancel button */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the image and position the close button */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container {
  padding: 16px;
  margin: 0 auto;
  height: 80%;
  width: 80%;
  display:flex;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 768px) {
  .right-side {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .loginContainer{
    width:80%!important;
      height: 80%;
      margin: 0 auto;
      position: relative;
      background:white;
      top: 10%;
  }

  h1{
    margin: 10px auto;
    width: auto;
  }

  .container{
    margin: 0 auto;
    position: relative;
    height: 80%;
    width: 80%;
    top: 5%;
    display:flex;
    flex-direction: column;
    justify-content:center;
  }

  .flex-container{
    opacity:1;
    background-image: linear-gradient(to left,#001B33,#001B33C9,#38A295);
    background-color: white;
    background-size: cover;
    background-position: center;
  }
}
</style>
